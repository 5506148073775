const isBrowser = typeof window !== "undefined"

const getFullsizeImage = image => {
  if (isBrowser && window.innerWidth < 480) {
    return `${image?.replace("c_scale,w_2000", "c_fill,w_480,h_1000")}`
  } else {
    return `${image}`
  }
}

export default getFullsizeImage
