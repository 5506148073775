import * as React from "react"
import { Helmet } from "react-helmet"
import { Link } from "gatsby"
import getFullsizeImage from "../../utils/getFullsizeImage"
import "../../styles/brand.scss"

const Brand = () => (
  <div
    style={{
      margin: `0 auto`
    }}
  >
    <Helmet>
      <script
        src="https://cdn.jsdelivr.net/npm/@popperjs/core@2.6.0/dist/umd/popper.min.js"
        integrity="sha384-KsvD1yqQ1/1+IA7gi3P0tyJcT3vR+NdBTt13hSJ2lnve8agRGXTTyNaBYmCR/Nwi"
        crossorigin="anonymous"
      ></script>
      <script
        src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.0-beta2/dist/js/bootstrap.min.js"
        integrity="sha384-nsg8ua9HAw1y0W1btsyWgBklPnCUAFLuTMS2G72MMONqmOymq585AcH49TLBQObG"
        crossorigin="anonymous"
      ></script>
    </Helmet>
    <main className="mb-0 brand">
      <section id="start" className="page white">
        <div className="page-header">
          <p>April 2023</p>
        </div>
        <div className="page-content">
          <h1>Djäkne Brand Guidelines</h1>
        </div>
        <div className="page-footer">
          <svg
            viewBox="0 0 980 308"
            xmlns="http://www.w3.org/2000/svg"
            fillRule="evenodd"
          >
            <g >
              <path d="M108.97 225.57L107.25 238.93H146.83L174.71 13.59H134.1L124.81 88.93C115.52 77.97 99.69 72.49 82.48 72.49C37.04 72.49 4 110.85 4 163.93C4 208.11 26.72 243.04 67.67 243.04C84.53 243.04 100.37 237.22 108.97 225.57V225.57ZM76.62 204C55.63 204 45.64 186.88 45.64 162.9C45.64 131.39 62.5 111.53 85.91 111.53C107.59 111.53 117.57 128.65 117.57 151.6C117.57 182.42 100.02 204 76.61 204H76.62Z" />
              <path d="M242.52 51.26C228.41 51.26 220.49 41.67 220.49 29.34C220.49 14.61 231.85 4 246.65 4C261.45 4 268.68 13.59 268.68 25.92C268.68 40.65 257.32 51.26 242.52 51.26ZM177.47 304C211.89 304 233.57 282.08 238.04 244.75L258.69 76.6H218.08L197.77 240.98C195.7 257.42 188.13 264.61 173.33 264.61C167.82 264.61 162.66 263.58 157.5 261.87L147.86 297.49C157.5 301.94 167.13 304 177.46 304H177.47Z" />
              <path d="M351.39 204C330.39 204 320.41 186.88 320.41 162.9C320.41 131.39 337.27 111.53 360.68 111.53C382.36 111.53 392.34 128.65 392.34 151.6C392.34 182.42 374.79 204 351.38 204H351.39ZM433.3 242.01L441.56 209.13C431.24 208.1 427.79 202.62 427.79 193.38C427.79 189.27 428.48 183.45 429.17 178.31L441.56 76.6H401.29L399.91 89.27C390.62 77.97 374.44 72.49 357.23 72.49C311.8 72.49 278.76 110.85 278.76 164.27C278.76 208.11 301.13 243.04 342.78 243.04C362.74 243.04 381.67 235.16 390.62 220.09C398.19 236.87 414.37 243.72 433.3 242.01V242.01ZM405.77 51.26C420.57 51.26 431.58 40.64 431.58 25.92C431.58 13.59 424.01 4 409.9 4C395.1 4 384.09 14.62 384.09 29.34C384.09 41.67 391.66 51.26 405.77 51.26ZM338.66 51.26C353.46 51.26 364.47 40.64 364.47 25.92C364.47 13.59 356.9 4 342.79 4C328.68 4 316.98 14.62 316.98 29.34C316.98 41.67 324.55 51.26 338.66 51.26Z" />
              <path d="M515.69 193.04L540.13 170.09L575.92 238.93H622.04L569.73 142.01L638.56 76.6H582.8L523.26 133.79L538.06 13.58H497.45L469.57 238.92H510.18L515.69 193.03V193.04Z" />
              <path d="M705.49 76.6H665.57L645.61 238.93H686.22L696.89 151.6C699.99 125.57 716.16 111.53 734.75 111.53C749.89 111.53 757.12 120.43 757.12 138.93C757.12 143.72 756.43 148.18 756.09 151.94L745.42 238.93H786.03L797.39 147.15C798.08 141.33 798.42 135.51 798.42 130.37C798.42 96.12 781.21 72.49 745.76 72.49C728.55 72.49 712.72 77.97 703.77 89.96L705.49 76.6V76.6Z" />
              <path d="M872.09 140.64C876.57 120.43 890.33 108.45 907.2 108.45C925.44 108.45 934.05 122.49 934.05 140.64H872.1H872.09ZM868.31 173.52H971.22C973.63 164.96 975.01 155.71 975.01 145.78C975.01 108.79 956.08 72.49 909.96 72.49C863.84 72.49 827.01 112.9 827.01 164.61C827.01 205.02 847.32 243.04 899.97 243.04C919.93 243.04 939.55 237.56 957.45 226.94L939.55 192.69C926.47 201.25 913.05 205.02 900.66 205.02C876.91 205.02 868.65 190.98 868.31 173.51V173.52Z" />
            </g>
          </svg>
          <Link to="#index">
            <h1>&rarr;</h1>
          </Link>
        </div>
      </section>
      <section id="index" className="page white">
        <div className="page-header">
          <svg
            viewBox="0 0 980 308"
            xmlns="http://www.w3.org/2000/svg"
            fillRule="evenodd"
          >
            <g >
              <path d="M108.97 225.57L107.25 238.93H146.83L174.71 13.59H134.1L124.81 88.93C115.52 77.97 99.69 72.49 82.48 72.49C37.04 72.49 4 110.85 4 163.93C4 208.11 26.72 243.04 67.67 243.04C84.53 243.04 100.37 237.22 108.97 225.57V225.57ZM76.62 204C55.63 204 45.64 186.88 45.64 162.9C45.64 131.39 62.5 111.53 85.91 111.53C107.59 111.53 117.57 128.65 117.57 151.6C117.57 182.42 100.02 204 76.61 204H76.62Z" />
              <path d="M242.52 51.26C228.41 51.26 220.49 41.67 220.49 29.34C220.49 14.61 231.85 4 246.65 4C261.45 4 268.68 13.59 268.68 25.92C268.68 40.65 257.32 51.26 242.52 51.26ZM177.47 304C211.89 304 233.57 282.08 238.04 244.75L258.69 76.6H218.08L197.77 240.98C195.7 257.42 188.13 264.61 173.33 264.61C167.82 264.61 162.66 263.58 157.5 261.87L147.86 297.49C157.5 301.94 167.13 304 177.46 304H177.47Z" />
              <path d="M351.39 204C330.39 204 320.41 186.88 320.41 162.9C320.41 131.39 337.27 111.53 360.68 111.53C382.36 111.53 392.34 128.65 392.34 151.6C392.34 182.42 374.79 204 351.38 204H351.39ZM433.3 242.01L441.56 209.13C431.24 208.1 427.79 202.62 427.79 193.38C427.79 189.27 428.48 183.45 429.17 178.31L441.56 76.6H401.29L399.91 89.27C390.62 77.97 374.44 72.49 357.23 72.49C311.8 72.49 278.76 110.85 278.76 164.27C278.76 208.11 301.13 243.04 342.78 243.04C362.74 243.04 381.67 235.16 390.62 220.09C398.19 236.87 414.37 243.72 433.3 242.01V242.01ZM405.77 51.26C420.57 51.26 431.58 40.64 431.58 25.92C431.58 13.59 424.01 4 409.9 4C395.1 4 384.09 14.62 384.09 29.34C384.09 41.67 391.66 51.26 405.77 51.26ZM338.66 51.26C353.46 51.26 364.47 40.64 364.47 25.92C364.47 13.59 356.9 4 342.79 4C328.68 4 316.98 14.62 316.98 29.34C316.98 41.67 324.55 51.26 338.66 51.26Z" />
              <path d="M515.69 193.04L540.13 170.09L575.92 238.93H622.04L569.73 142.01L638.56 76.6H582.8L523.26 133.79L538.06 13.58H497.45L469.57 238.92H510.18L515.69 193.03V193.04Z" />
              <path d="M705.49 76.6H665.57L645.61 238.93H686.22L696.89 151.6C699.99 125.57 716.16 111.53 734.75 111.53C749.89 111.53 757.12 120.43 757.12 138.93C757.12 143.72 756.43 148.18 756.09 151.94L745.42 238.93H786.03L797.39 147.15C798.08 141.33 798.42 135.51 798.42 130.37C798.42 96.12 781.21 72.49 745.76 72.49C728.55 72.49 712.72 77.97 703.77 89.96L705.49 76.6V76.6Z" />
              <path d="M872.09 140.64C876.57 120.43 890.33 108.45 907.2 108.45C925.44 108.45 934.05 122.49 934.05 140.64H872.1H872.09ZM868.31 173.52H971.22C973.63 164.96 975.01 155.71 975.01 145.78C975.01 108.79 956.08 72.49 909.96 72.49C863.84 72.49 827.01 112.9 827.01 164.61C827.01 205.02 847.32 243.04 899.97 243.04C919.93 243.04 939.55 237.56 957.45 226.94L939.55 192.69C926.47 201.25 913.05 205.02 900.66 205.02C876.91 205.02 868.65 190.98 868.31 173.51V173.52Z" />
            </g>
          </svg>
          <p className="dimmed">Djäkne Brand Guidelines</p>
        </div>
        <div className="page-content">
          <h2 className="">Foundation</h2>
          <ol className="lead mb-4">
            <li className="">
              <Link to="#story">The story</Link>
            </li>
            <li className="">
              <Link to="#whw">Why, how, what?</Link>
            </li>
            <li className="">
              <Link to="#vision">Vision</Link>
            </li>
            <li className="">
              <Link to="#principles">Principles</Link>
            </li>
            <li className="">
              <Link to="#audience">Our Audience</Link>
            </li>
          </ol>
          <h2 className="mt-4">Guidelines</h2>
          <ol className="lead">
            <li className="">
              <Link to="#voice">Voice</Link>
            </li>
            <li className="">
              <Link to="#logo-1">Logotype</Link>
            </li>
            <li className="">
              <Link to="#typography">Typography</Link>
            </li>
            <li className="">
              <Link to="#colors">Colors</Link>
            </li>
            <li className="">
              <Link to="#name">Use of the company name</Link>
            </li>

            <li className="">
              <Link to="#photography">Photography</Link>
            </li>
          </ol>
        </div>
        <div className="page-footer">
          <h1></h1>
          <Link to="#foundation">
            <h1>&rarr;</h1>
          </Link>
        </div>
      </section>
      <section id="foundation" className="page">
        <div className="page-header">
          <Link to="#index">
            <svg
              viewBox="0 0 980 308"
              xmlns="http://www.w3.org/2000/svg"
              fillRule="evenodd"
            >
              <g >
                <path d="M108.97 225.57L107.25 238.93H146.83L174.71 13.59H134.1L124.81 88.93C115.52 77.97 99.69 72.49 82.48 72.49C37.04 72.49 4 110.85 4 163.93C4 208.11 26.72 243.04 67.67 243.04C84.53 243.04 100.37 237.22 108.97 225.57V225.57ZM76.62 204C55.63 204 45.64 186.88 45.64 162.9C45.64 131.39 62.5 111.53 85.91 111.53C107.59 111.53 117.57 128.65 117.57 151.6C117.57 182.42 100.02 204 76.61 204H76.62Z" />
                <path d="M242.52 51.26C228.41 51.26 220.49 41.67 220.49 29.34C220.49 14.61 231.85 4 246.65 4C261.45 4 268.68 13.59 268.68 25.92C268.68 40.65 257.32 51.26 242.52 51.26ZM177.47 304C211.89 304 233.57 282.08 238.04 244.75L258.69 76.6H218.08L197.77 240.98C195.7 257.42 188.13 264.61 173.33 264.61C167.82 264.61 162.66 263.58 157.5 261.87L147.86 297.49C157.5 301.94 167.13 304 177.46 304H177.47Z" />
                <path d="M351.39 204C330.39 204 320.41 186.88 320.41 162.9C320.41 131.39 337.27 111.53 360.68 111.53C382.36 111.53 392.34 128.65 392.34 151.6C392.34 182.42 374.79 204 351.38 204H351.39ZM433.3 242.01L441.56 209.13C431.24 208.1 427.79 202.62 427.79 193.38C427.79 189.27 428.48 183.45 429.17 178.31L441.56 76.6H401.29L399.91 89.27C390.62 77.97 374.44 72.49 357.23 72.49C311.8 72.49 278.76 110.85 278.76 164.27C278.76 208.11 301.13 243.04 342.78 243.04C362.74 243.04 381.67 235.16 390.62 220.09C398.19 236.87 414.37 243.72 433.3 242.01V242.01ZM405.77 51.26C420.57 51.26 431.58 40.64 431.58 25.92C431.58 13.59 424.01 4 409.9 4C395.1 4 384.09 14.62 384.09 29.34C384.09 41.67 391.66 51.26 405.77 51.26ZM338.66 51.26C353.46 51.26 364.47 40.64 364.47 25.92C364.47 13.59 356.9 4 342.79 4C328.68 4 316.98 14.62 316.98 29.34C316.98 41.67 324.55 51.26 338.66 51.26Z" />
                <path d="M515.69 193.04L540.13 170.09L575.92 238.93H622.04L569.73 142.01L638.56 76.6H582.8L523.26 133.79L538.06 13.58H497.45L469.57 238.92H510.18L515.69 193.03V193.04Z" />
                <path d="M705.49 76.6H665.57L645.61 238.93H686.22L696.89 151.6C699.99 125.57 716.16 111.53 734.75 111.53C749.89 111.53 757.12 120.43 757.12 138.93C757.12 143.72 756.43 148.18 756.09 151.94L745.42 238.93H786.03L797.39 147.15C798.08 141.33 798.42 135.51 798.42 130.37C798.42 96.12 781.21 72.49 745.76 72.49C728.55 72.49 712.72 77.97 703.77 89.96L705.49 76.6V76.6Z" />
                <path d="M872.09 140.64C876.57 120.43 890.33 108.45 907.2 108.45C925.44 108.45 934.05 122.49 934.05 140.64H872.1H872.09ZM868.31 173.52H971.22C973.63 164.96 975.01 155.71 975.01 145.78C975.01 108.79 956.08 72.49 909.96 72.49C863.84 72.49 827.01 112.9 827.01 164.61C827.01 205.02 847.32 243.04 899.97 243.04C919.93 243.04 939.55 237.56 957.45 226.94L939.55 192.69C926.47 201.25 913.05 205.02 900.66 205.02C876.91 205.02 868.65 190.98 868.31 173.51V173.52Z" />
              </g>
            </svg>
          </Link>
          <p className="dimmed">Brand Guidelines / Foundation</p>
        </div>
        <div className="page-content">
          <h1>Foundation</h1>
        </div>
        <div className="page-footer">
          <Link to="#index">
            <h1>&larr;</h1>
          </Link>
          <Link to="#story">
            <h1>&rarr;</h1>
          </Link>
        </div>
      </section>
      <section id="story" className="page white split">
        <div className="page-header">
          <Link to="#index">
            <svg
              viewBox="0 0 980 308"
              xmlns="http://www.w3.org/2000/svg"
              fillRule="evenodd"
            >
              <g >
                <path d="M108.97 225.57L107.25 238.93H146.83L174.71 13.59H134.1L124.81 88.93C115.52 77.97 99.69 72.49 82.48 72.49C37.04 72.49 4 110.85 4 163.93C4 208.11 26.72 243.04 67.67 243.04C84.53 243.04 100.37 237.22 108.97 225.57V225.57ZM76.62 204C55.63 204 45.64 186.88 45.64 162.9C45.64 131.39 62.5 111.53 85.91 111.53C107.59 111.53 117.57 128.65 117.57 151.6C117.57 182.42 100.02 204 76.61 204H76.62Z" />
                <path d="M242.52 51.26C228.41 51.26 220.49 41.67 220.49 29.34C220.49 14.61 231.85 4 246.65 4C261.45 4 268.68 13.59 268.68 25.92C268.68 40.65 257.32 51.26 242.52 51.26ZM177.47 304C211.89 304 233.57 282.08 238.04 244.75L258.69 76.6H218.08L197.77 240.98C195.7 257.42 188.13 264.61 173.33 264.61C167.82 264.61 162.66 263.58 157.5 261.87L147.86 297.49C157.5 301.94 167.13 304 177.46 304H177.47Z" />
                <path d="M351.39 204C330.39 204 320.41 186.88 320.41 162.9C320.41 131.39 337.27 111.53 360.68 111.53C382.36 111.53 392.34 128.65 392.34 151.6C392.34 182.42 374.79 204 351.38 204H351.39ZM433.3 242.01L441.56 209.13C431.24 208.1 427.79 202.62 427.79 193.38C427.79 189.27 428.48 183.45 429.17 178.31L441.56 76.6H401.29L399.91 89.27C390.62 77.97 374.44 72.49 357.23 72.49C311.8 72.49 278.76 110.85 278.76 164.27C278.76 208.11 301.13 243.04 342.78 243.04C362.74 243.04 381.67 235.16 390.62 220.09C398.19 236.87 414.37 243.72 433.3 242.01V242.01ZM405.77 51.26C420.57 51.26 431.58 40.64 431.58 25.92C431.58 13.59 424.01 4 409.9 4C395.1 4 384.09 14.62 384.09 29.34C384.09 41.67 391.66 51.26 405.77 51.26ZM338.66 51.26C353.46 51.26 364.47 40.64 364.47 25.92C364.47 13.59 356.9 4 342.79 4C328.68 4 316.98 14.62 316.98 29.34C316.98 41.67 324.55 51.26 338.66 51.26Z" />
                <path d="M515.69 193.04L540.13 170.09L575.92 238.93H622.04L569.73 142.01L638.56 76.6H582.8L523.26 133.79L538.06 13.58H497.45L469.57 238.92H510.18L515.69 193.03V193.04Z" />
                <path d="M705.49 76.6H665.57L645.61 238.93H686.22L696.89 151.6C699.99 125.57 716.16 111.53 734.75 111.53C749.89 111.53 757.12 120.43 757.12 138.93C757.12 143.72 756.43 148.18 756.09 151.94L745.42 238.93H786.03L797.39 147.15C798.08 141.33 798.42 135.51 798.42 130.37C798.42 96.12 781.21 72.49 745.76 72.49C728.55 72.49 712.72 77.97 703.77 89.96L705.49 76.6V76.6Z" />
                <path d="M872.09 140.64C876.57 120.43 890.33 108.45 907.2 108.45C925.44 108.45 934.05 122.49 934.05 140.64H872.1H872.09ZM868.31 173.52H971.22C973.63 164.96 975.01 155.71 975.01 145.78C975.01 108.79 956.08 72.49 909.96 72.49C863.84 72.49 827.01 112.9 827.01 164.61C827.01 205.02 847.32 243.04 899.97 243.04C919.93 243.04 939.55 237.56 957.45 226.94L939.55 192.69C926.47 201.25 913.05 205.02 900.66 205.02C876.91 205.02 868.65 190.98 868.31 173.51V173.52Z" />
              </g>
            </svg>
          </Link>
          <p className="dimmed">Brand Guidelines / Foundation</p>
        </div>
        <div className="page-content">
          <div className="left">
            <div className="text-container">
              <h2>
                Brand story
              </h2>
            </div>
          </div>
          <div className="right">
            <div className="text-container">
              <h1 className="my-4">What makes us happy</h1>
              <p>
                Too many people realize too late what makes them happy – we knew
                it from the start. And we have fought for it ever since. Of
                course we didn’t know that we would become a startup studio, but
                we were well aware of our attraction to the unknown, the
                unexplored and the challenge to solve what seemed impossible.
                When two of us first met in the early 2000 and recognized the
                hunger in each other for creating stuff the world had never seen
                before – we knew that we were on to something.
              </p>
              <p>
                The famous snowball started to roll and two people became six
                that became many more. All soulmates who gathered around the
                exciting feeling of coming up with new tech business ideas,
                proving them and sometimes watching them fly. No hierarchies. No
                bosses. And definitely no titles. There was work to be done. And
                so much fun! Everyone found their way to navigate and everyone
                ran like the wind. What more could be around the corner?
              </p>
              <p>
                It would be easy to say that we were opportunistic, but that was
                not the case at all. We knew what we wanted to achieve – a
                workplace for skilled and self-motivated people who were crazy
                enough to commit themselves to tech projects they didn’t know
                the outcome of. The only thing we could guarantee was a never
                ending adventure. And it still is.
              </p>
              <p>
                Curiosity has led our way so far, and it will continue to do so.
                Many projects have been buried along the road, others have made
                it all the way to hungry customers. And some have been listed on
                the stock market. Or sold. We are happy as long as we can do
                what we love the most – exploring the unknown and sharing the
                adventure with people we trust. Where it all started, it
                continues: Can this be done? Let’s find out.
              </p>
            </div>
          </div>
        </div>
        <div className="page-footer">
          <Link to="#foundation">
            <h1>&larr;</h1>
          </Link>
          <Link to="#whw">
            <h1>&rarr;</h1>
          </Link>
        </div>
      </section>
      <section id="whw" className="page white split">
        <div className="page-header">
          <Link to="#index">
            <svg
              viewBox="0 0 980 308"
              xmlns="http://www.w3.org/2000/svg"
              fillRule="evenodd"
            >
              <g >
                <path d="M108.97 225.57L107.25 238.93H146.83L174.71 13.59H134.1L124.81 88.93C115.52 77.97 99.69 72.49 82.48 72.49C37.04 72.49 4 110.85 4 163.93C4 208.11 26.72 243.04 67.67 243.04C84.53 243.04 100.37 237.22 108.97 225.57V225.57ZM76.62 204C55.63 204 45.64 186.88 45.64 162.9C45.64 131.39 62.5 111.53 85.91 111.53C107.59 111.53 117.57 128.65 117.57 151.6C117.57 182.42 100.02 204 76.61 204H76.62Z" />
                <path d="M242.52 51.26C228.41 51.26 220.49 41.67 220.49 29.34C220.49 14.61 231.85 4 246.65 4C261.45 4 268.68 13.59 268.68 25.92C268.68 40.65 257.32 51.26 242.52 51.26ZM177.47 304C211.89 304 233.57 282.08 238.04 244.75L258.69 76.6H218.08L197.77 240.98C195.7 257.42 188.13 264.61 173.33 264.61C167.82 264.61 162.66 263.58 157.5 261.87L147.86 297.49C157.5 301.94 167.13 304 177.46 304H177.47Z" />
                <path d="M351.39 204C330.39 204 320.41 186.88 320.41 162.9C320.41 131.39 337.27 111.53 360.68 111.53C382.36 111.53 392.34 128.65 392.34 151.6C392.34 182.42 374.79 204 351.38 204H351.39ZM433.3 242.01L441.56 209.13C431.24 208.1 427.79 202.62 427.79 193.38C427.79 189.27 428.48 183.45 429.17 178.31L441.56 76.6H401.29L399.91 89.27C390.62 77.97 374.44 72.49 357.23 72.49C311.8 72.49 278.76 110.85 278.76 164.27C278.76 208.11 301.13 243.04 342.78 243.04C362.74 243.04 381.67 235.16 390.62 220.09C398.19 236.87 414.37 243.72 433.3 242.01V242.01ZM405.77 51.26C420.57 51.26 431.58 40.64 431.58 25.92C431.58 13.59 424.01 4 409.9 4C395.1 4 384.09 14.62 384.09 29.34C384.09 41.67 391.66 51.26 405.77 51.26ZM338.66 51.26C353.46 51.26 364.47 40.64 364.47 25.92C364.47 13.59 356.9 4 342.79 4C328.68 4 316.98 14.62 316.98 29.34C316.98 41.67 324.55 51.26 338.66 51.26Z" />
                <path d="M515.69 193.04L540.13 170.09L575.92 238.93H622.04L569.73 142.01L638.56 76.6H582.8L523.26 133.79L538.06 13.58H497.45L469.57 238.92H510.18L515.69 193.03V193.04Z" />
                <path d="M705.49 76.6H665.57L645.61 238.93H686.22L696.89 151.6C699.99 125.57 716.16 111.53 734.75 111.53C749.89 111.53 757.12 120.43 757.12 138.93C757.12 143.72 756.43 148.18 756.09 151.94L745.42 238.93H786.03L797.39 147.15C798.08 141.33 798.42 135.51 798.42 130.37C798.42 96.12 781.21 72.49 745.76 72.49C728.55 72.49 712.72 77.97 703.77 89.96L705.49 76.6V76.6Z" />
                <path d="M872.09 140.64C876.57 120.43 890.33 108.45 907.2 108.45C925.44 108.45 934.05 122.49 934.05 140.64H872.1H872.09ZM868.31 173.52H971.22C973.63 164.96 975.01 155.71 975.01 145.78C975.01 108.79 956.08 72.49 909.96 72.49C863.84 72.49 827.01 112.9 827.01 164.61C827.01 205.02 847.32 243.04 899.97 243.04C919.93 243.04 939.55 237.56 957.45 226.94L939.55 192.69C926.47 201.25 913.05 205.02 900.66 205.02C876.91 205.02 868.65 190.98 868.31 173.51V173.52Z" />
              </g>
            </svg>
          </Link>
          <p className="dimmed">Brand Guidelines / Foundation</p>
        </div>
        <div className="page-content">
          <div className="left">
            <div className="text-container">
              <h2>
                Why, how, what?
              </h2>
              <p className="info">
                The why, how and what explains why we are in business, how we
                succeed and what we do. The purpose, the process and the result.
              </p>
            </div>
          </div>
          <div className="right">
            <div className="text-container">
              <h3 className="mt-5">
                “We are curious about the future. Therefore, we gather skilled
                and friendly people who want to have fun and be challenged by
                projects with life-changing potential. Together we start and
                build tech companies.”
              </h3>
            </div>
          </div>
        </div>
        <div className="page-footer">
          <Link to="#story">
            <h1>&larr;</h1>
          </Link>
          <Link to="#vision">
            <h1>&rarr;</h1>
          </Link>
        </div>
      </section>
      <section id="vision" className="page white split">
        <div className="page-header">
          <Link to="#index">
            <svg
              viewBox="0 0 980 308"
              xmlns="http://www.w3.org/2000/svg"
              fillRule="evenodd"
            >
              <g >
                <path d="M108.97 225.57L107.25 238.93H146.83L174.71 13.59H134.1L124.81 88.93C115.52 77.97 99.69 72.49 82.48 72.49C37.04 72.49 4 110.85 4 163.93C4 208.11 26.72 243.04 67.67 243.04C84.53 243.04 100.37 237.22 108.97 225.57V225.57ZM76.62 204C55.63 204 45.64 186.88 45.64 162.9C45.64 131.39 62.5 111.53 85.91 111.53C107.59 111.53 117.57 128.65 117.57 151.6C117.57 182.42 100.02 204 76.61 204H76.62Z" />
                <path d="M242.52 51.26C228.41 51.26 220.49 41.67 220.49 29.34C220.49 14.61 231.85 4 246.65 4C261.45 4 268.68 13.59 268.68 25.92C268.68 40.65 257.32 51.26 242.52 51.26ZM177.47 304C211.89 304 233.57 282.08 238.04 244.75L258.69 76.6H218.08L197.77 240.98C195.7 257.42 188.13 264.61 173.33 264.61C167.82 264.61 162.66 263.58 157.5 261.87L147.86 297.49C157.5 301.94 167.13 304 177.46 304H177.47Z" />
                <path d="M351.39 204C330.39 204 320.41 186.88 320.41 162.9C320.41 131.39 337.27 111.53 360.68 111.53C382.36 111.53 392.34 128.65 392.34 151.6C392.34 182.42 374.79 204 351.38 204H351.39ZM433.3 242.01L441.56 209.13C431.24 208.1 427.79 202.62 427.79 193.38C427.79 189.27 428.48 183.45 429.17 178.31L441.56 76.6H401.29L399.91 89.27C390.62 77.97 374.44 72.49 357.23 72.49C311.8 72.49 278.76 110.85 278.76 164.27C278.76 208.11 301.13 243.04 342.78 243.04C362.74 243.04 381.67 235.16 390.62 220.09C398.19 236.87 414.37 243.72 433.3 242.01V242.01ZM405.77 51.26C420.57 51.26 431.58 40.64 431.58 25.92C431.58 13.59 424.01 4 409.9 4C395.1 4 384.09 14.62 384.09 29.34C384.09 41.67 391.66 51.26 405.77 51.26ZM338.66 51.26C353.46 51.26 364.47 40.64 364.47 25.92C364.47 13.59 356.9 4 342.79 4C328.68 4 316.98 14.62 316.98 29.34C316.98 41.67 324.55 51.26 338.66 51.26Z" />
                <path d="M515.69 193.04L540.13 170.09L575.92 238.93H622.04L569.73 142.01L638.56 76.6H582.8L523.26 133.79L538.06 13.58H497.45L469.57 238.92H510.18L515.69 193.03V193.04Z" />
                <path d="M705.49 76.6H665.57L645.61 238.93H686.22L696.89 151.6C699.99 125.57 716.16 111.53 734.75 111.53C749.89 111.53 757.12 120.43 757.12 138.93C757.12 143.72 756.43 148.18 756.09 151.94L745.42 238.93H786.03L797.39 147.15C798.08 141.33 798.42 135.51 798.42 130.37C798.42 96.12 781.21 72.49 745.76 72.49C728.55 72.49 712.72 77.97 703.77 89.96L705.49 76.6V76.6Z" />
                <path d="M872.09 140.64C876.57 120.43 890.33 108.45 907.2 108.45C925.44 108.45 934.05 122.49 934.05 140.64H872.1H872.09ZM868.31 173.52H971.22C973.63 164.96 975.01 155.71 975.01 145.78C975.01 108.79 956.08 72.49 909.96 72.49C863.84 72.49 827.01 112.9 827.01 164.61C827.01 205.02 847.32 243.04 899.97 243.04C919.93 243.04 939.55 237.56 957.45 226.94L939.55 192.69C926.47 201.25 913.05 205.02 900.66 205.02C876.91 205.02 868.65 190.98 868.31 173.51V173.52Z" />
              </g>
            </svg>
          </Link>
          <p className="dimmed">Brand Guidelines / Foundation</p>
        </div>
        <div className="page-content">
          <div className="left">
            <div className="text-container">
              <h2>
                Vision
              </h2>
              <p className="info">
                Our vision describes how our “why” comes alive. It provides a
                common direction and reflects what we are already doing, as well
                as what we are striving for.
              </p>
            </div>
          </div>
          <div className="right">
            <div className="vision-container">
              <h3 className="display-3 my-4">
                <span className="tooltip-anchor turning tbd-blur">Turning</span>
                &nbsp;
                <span className="tooltip-anchor ideas tbd-blur">ideas</span>
                &nbsp;
                <span className="tbd-blur">into</span>&nbsp;
                <span className="tooltip-anchor adventures tbd-blur">
                  adventures
                </span>
              </h3>
            </div>
            <div className="text-container">
              <h3 className="mt-5 mb-3">We commit to turn ideas into adventures</h3>
              <p className="">
                Changing the world can be quite an adventure. There are so many
                challenges along the way and so many problems to be solved. The
                unknown doesn’t scare us – we love to figure out a way forward
                to make great things happen. We strive to be the best team to
                give tech entrepreneurs the best possible chance to succeed.
              </p>
            </div>
          </div>
        </div>
        <div className="page-footer">
          <Link to="#whw">
            <h1>&larr;</h1>
          </Link>
          <Link to="#principles">
            <h1>&rarr;</h1>
          </Link>
        </div>
      </section>

      <section id="principles" className="page white split">
        <div className="page-header">
          <Link to="#index">
            <svg
              viewBox="0 0 980 308"
              xmlns="http://www.w3.org/2000/svg"
              fillRule="evenodd"
            >
              <g >
                <path d="M108.97 225.57L107.25 238.93H146.83L174.71 13.59H134.1L124.81 88.93C115.52 77.97 99.69 72.49 82.48 72.49C37.04 72.49 4 110.85 4 163.93C4 208.11 26.72 243.04 67.67 243.04C84.53 243.04 100.37 237.22 108.97 225.57V225.57ZM76.62 204C55.63 204 45.64 186.88 45.64 162.9C45.64 131.39 62.5 111.53 85.91 111.53C107.59 111.53 117.57 128.65 117.57 151.6C117.57 182.42 100.02 204 76.61 204H76.62Z" />
                <path d="M242.52 51.26C228.41 51.26 220.49 41.67 220.49 29.34C220.49 14.61 231.85 4 246.65 4C261.45 4 268.68 13.59 268.68 25.92C268.68 40.65 257.32 51.26 242.52 51.26ZM177.47 304C211.89 304 233.57 282.08 238.04 244.75L258.69 76.6H218.08L197.77 240.98C195.7 257.42 188.13 264.61 173.33 264.61C167.82 264.61 162.66 263.58 157.5 261.87L147.86 297.49C157.5 301.94 167.13 304 177.46 304H177.47Z" />
                <path d="M351.39 204C330.39 204 320.41 186.88 320.41 162.9C320.41 131.39 337.27 111.53 360.68 111.53C382.36 111.53 392.34 128.65 392.34 151.6C392.34 182.42 374.79 204 351.38 204H351.39ZM433.3 242.01L441.56 209.13C431.24 208.1 427.79 202.62 427.79 193.38C427.79 189.27 428.48 183.45 429.17 178.31L441.56 76.6H401.29L399.91 89.27C390.62 77.97 374.44 72.49 357.23 72.49C311.8 72.49 278.76 110.85 278.76 164.27C278.76 208.11 301.13 243.04 342.78 243.04C362.74 243.04 381.67 235.16 390.62 220.09C398.19 236.87 414.37 243.72 433.3 242.01V242.01ZM405.77 51.26C420.57 51.26 431.58 40.64 431.58 25.92C431.58 13.59 424.01 4 409.9 4C395.1 4 384.09 14.62 384.09 29.34C384.09 41.67 391.66 51.26 405.77 51.26ZM338.66 51.26C353.46 51.26 364.47 40.64 364.47 25.92C364.47 13.59 356.9 4 342.79 4C328.68 4 316.98 14.62 316.98 29.34C316.98 41.67 324.55 51.26 338.66 51.26Z" />
                <path d="M515.69 193.04L540.13 170.09L575.92 238.93H622.04L569.73 142.01L638.56 76.6H582.8L523.26 133.79L538.06 13.58H497.45L469.57 238.92H510.18L515.69 193.03V193.04Z" />
                <path d="M705.49 76.6H665.57L645.61 238.93H686.22L696.89 151.6C699.99 125.57 716.16 111.53 734.75 111.53C749.89 111.53 757.12 120.43 757.12 138.93C757.12 143.72 756.43 148.18 756.09 151.94L745.42 238.93H786.03L797.39 147.15C798.08 141.33 798.42 135.51 798.42 130.37C798.42 96.12 781.21 72.49 745.76 72.49C728.55 72.49 712.72 77.97 703.77 89.96L705.49 76.6V76.6Z" />
                <path d="M872.09 140.64C876.57 120.43 890.33 108.45 907.2 108.45C925.44 108.45 934.05 122.49 934.05 140.64H872.1H872.09ZM868.31 173.52H971.22C973.63 164.96 975.01 155.71 975.01 145.78C975.01 108.79 956.08 72.49 909.96 72.49C863.84 72.49 827.01 112.9 827.01 164.61C827.01 205.02 847.32 243.04 899.97 243.04C919.93 243.04 939.55 237.56 957.45 226.94L939.55 192.69C926.47 201.25 913.05 205.02 900.66 205.02C876.91 205.02 868.65 190.98 868.31 173.51V173.52Z" />
              </g>
            </svg>
          </Link>
          <p className="dimmed">Brand Guidelines / Foundation</p>
        </div>
        <div className="page-content">
          <div className="left">
            <div className="text-container">
              <h2>
                Brand Principles
              </h2>
              <p className="info">
                The principles are our guiding star for decision-making in order
                to turn ideas into adventures.
              </p>
            </div>
          </div>
          <div className="right">
            <div className="principles mt-5">
              <div className="principle me-5 my-3">
                <h4>At the forefront.</h4>
                <p>
                  Emerging trends and new technology are serious stuff that
                  makes us feel alive. We explore and wonder where it can take
                  us.
                </p>
              </div>
              <div className="principle me-5 my-3">
                <h4>Enjoy the ride.</h4>
                <p>
                  The startup business is unpredictable in every sense. Be
                  brave, adapt smoothly and enjoy being part of building the
                  future.
                </p>
              </div>
              <div className="principle me-5 my-3">
                <h4>Best for the business.</h4>
                <p>
                  When the way is unclear and choices are many, we commit to
                  make judicious decisions in the best interest of the company.
                </p>
              </div>
              <div className="principle me-5 my-3">
                <h4>Keep on going.</h4>
                <p>
                  True competence and experiences don’t need the big stages.
                  Tell the story as it is. Let the rumor spread. Get back to
                  work.
                </p>
              </div>
              <div className="principle me-5 my-3">
                <h4>Stay true to yourself.</h4>
                <p>
                  The more nerd, the more passion. Use it, share it, live it. Be
                  more you – and learn from other incredible talents.
                </p>
              </div>
              <div className="principle me-5 my-3">
                <h4>Think about tomorrow.</h4>
                <p>
                  What we can do today to make a difference tomorrow, we will do
                  it. Make sure to invest wisely in business, relationships and
                  life itself.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="page-footer">
          <Link to="#whw">
            <h1>&larr;</h1>
          </Link>
          <Link to="#audience">
            <h1>&rarr;</h1>
          </Link>
        </div>
      </section>
      <section id="audience" className="page white split">
        <div className="page-header">
          <Link to="#index">
            <svg
              viewBox="0 0 980 308"
              xmlns="http://www.w3.org/2000/svg"
              fillRule="evenodd"
            >
              <g >
                <path d="M108.97 225.57L107.25 238.93H146.83L174.71 13.59H134.1L124.81 88.93C115.52 77.97 99.69 72.49 82.48 72.49C37.04 72.49 4 110.85 4 163.93C4 208.11 26.72 243.04 67.67 243.04C84.53 243.04 100.37 237.22 108.97 225.57V225.57ZM76.62 204C55.63 204 45.64 186.88 45.64 162.9C45.64 131.39 62.5 111.53 85.91 111.53C107.59 111.53 117.57 128.65 117.57 151.6C117.57 182.42 100.02 204 76.61 204H76.62Z" />
                <path d="M242.52 51.26C228.41 51.26 220.49 41.67 220.49 29.34C220.49 14.61 231.85 4 246.65 4C261.45 4 268.68 13.59 268.68 25.92C268.68 40.65 257.32 51.26 242.52 51.26ZM177.47 304C211.89 304 233.57 282.08 238.04 244.75L258.69 76.6H218.08L197.77 240.98C195.7 257.42 188.13 264.61 173.33 264.61C167.82 264.61 162.66 263.58 157.5 261.87L147.86 297.49C157.5 301.94 167.13 304 177.46 304H177.47Z" />
                <path d="M351.39 204C330.39 204 320.41 186.88 320.41 162.9C320.41 131.39 337.27 111.53 360.68 111.53C382.36 111.53 392.34 128.65 392.34 151.6C392.34 182.42 374.79 204 351.38 204H351.39ZM433.3 242.01L441.56 209.13C431.24 208.1 427.79 202.62 427.79 193.38C427.79 189.27 428.48 183.45 429.17 178.31L441.56 76.6H401.29L399.91 89.27C390.62 77.97 374.44 72.49 357.23 72.49C311.8 72.49 278.76 110.85 278.76 164.27C278.76 208.11 301.13 243.04 342.78 243.04C362.74 243.04 381.67 235.16 390.62 220.09C398.19 236.87 414.37 243.72 433.3 242.01V242.01ZM405.77 51.26C420.57 51.26 431.58 40.64 431.58 25.92C431.58 13.59 424.01 4 409.9 4C395.1 4 384.09 14.62 384.09 29.34C384.09 41.67 391.66 51.26 405.77 51.26ZM338.66 51.26C353.46 51.26 364.47 40.64 364.47 25.92C364.47 13.59 356.9 4 342.79 4C328.68 4 316.98 14.62 316.98 29.34C316.98 41.67 324.55 51.26 338.66 51.26Z" />
                <path d="M515.69 193.04L540.13 170.09L575.92 238.93H622.04L569.73 142.01L638.56 76.6H582.8L523.26 133.79L538.06 13.58H497.45L469.57 238.92H510.18L515.69 193.03V193.04Z" />
                <path d="M705.49 76.6H665.57L645.61 238.93H686.22L696.89 151.6C699.99 125.57 716.16 111.53 734.75 111.53C749.89 111.53 757.12 120.43 757.12 138.93C757.12 143.72 756.43 148.18 756.09 151.94L745.42 238.93H786.03L797.39 147.15C798.08 141.33 798.42 135.51 798.42 130.37C798.42 96.12 781.21 72.49 745.76 72.49C728.55 72.49 712.72 77.97 703.77 89.96L705.49 76.6V76.6Z" />
                <path d="M872.09 140.64C876.57 120.43 890.33 108.45 907.2 108.45C925.44 108.45 934.05 122.49 934.05 140.64H872.1H872.09ZM868.31 173.52H971.22C973.63 164.96 975.01 155.71 975.01 145.78C975.01 108.79 956.08 72.49 909.96 72.49C863.84 72.49 827.01 112.9 827.01 164.61C827.01 205.02 847.32 243.04 899.97 243.04C919.93 243.04 939.55 237.56 957.45 226.94L939.55 192.69C926.47 201.25 913.05 205.02 900.66 205.02C876.91 205.02 868.65 190.98 868.31 173.51V173.52Z" />
              </g>
            </svg>
          </Link>
          <p className="dimmed">Brand Guidelines / Foundation</p>
        </div>
        <div className="page-content">
          <div className="left">
            <div className="text-container">
              <h2>
                Our audience
              </h2>
              <p className="info">
                The audience describes who we are interacting with, serving and
                supporting.
              </p>
            </div>
          </div>
          <div className="right">
            <div className="text-container">
              <h1 className="mb-4 mt-5">
                The up and coming entrepreneur
              </h1>
              <p>
                To be completely honest, Djäkne Startup Studio didn’t have a
                clear target group in the beginning. The founders wanted to be
                entrepreneurs, see the world and get a kick out of some risky
                business. It all turned out to be more successful than they ever
                dreamed of, and they started to pay it forward. Perhaps, newly
                hatched entrepreneurs could learn and grow by their side.
              </p>
              <p className="mb-5">
                With Djäkne Startup Studio as a platform, our love for
                entrepreneurship can flourish. We are devoted to encouraging and
                supporting tech entrepreneurs who want to make a difference –
                wherever they are – someone who is following us online, is part
                of our company portfolio or a teammate who has come up with a
                splendid idea. Our job is to share our experience to create the
                best possible conditions for them to scale their businesses and
                realize their dreams.
              </p>
            </div>
          </div>
        </div>
        <div className="page-footer">
          <Link to="#principles">
            <h1>&larr;</h1>
          </Link>
          <Link to="#guidelines">
            <h1>&rarr;</h1>
          </Link>
        </div>
      </section>
      <section id="guidelines" className="page">
        <div className="page-header">
          <Link to="#index">
            <svg
              viewBox="0 0 980 308"
              xmlns="http://www.w3.org/2000/svg"
              fillRule="evenodd"
            >
              <g >
                <path d="M108.97 225.57L107.25 238.93H146.83L174.71 13.59H134.1L124.81 88.93C115.52 77.97 99.69 72.49 82.48 72.49C37.04 72.49 4 110.85 4 163.93C4 208.11 26.72 243.04 67.67 243.04C84.53 243.04 100.37 237.22 108.97 225.57V225.57ZM76.62 204C55.63 204 45.64 186.88 45.64 162.9C45.64 131.39 62.5 111.53 85.91 111.53C107.59 111.53 117.57 128.65 117.57 151.6C117.57 182.42 100.02 204 76.61 204H76.62Z" />
                <path d="M242.52 51.26C228.41 51.26 220.49 41.67 220.49 29.34C220.49 14.61 231.85 4 246.65 4C261.45 4 268.68 13.59 268.68 25.92C268.68 40.65 257.32 51.26 242.52 51.26ZM177.47 304C211.89 304 233.57 282.08 238.04 244.75L258.69 76.6H218.08L197.77 240.98C195.7 257.42 188.13 264.61 173.33 264.61C167.82 264.61 162.66 263.58 157.5 261.87L147.86 297.49C157.5 301.94 167.13 304 177.46 304H177.47Z" />
                <path d="M351.39 204C330.39 204 320.41 186.88 320.41 162.9C320.41 131.39 337.27 111.53 360.68 111.53C382.36 111.53 392.34 128.65 392.34 151.6C392.34 182.42 374.79 204 351.38 204H351.39ZM433.3 242.01L441.56 209.13C431.24 208.1 427.79 202.62 427.79 193.38C427.79 189.27 428.48 183.45 429.17 178.31L441.56 76.6H401.29L399.91 89.27C390.62 77.97 374.44 72.49 357.23 72.49C311.8 72.49 278.76 110.85 278.76 164.27C278.76 208.11 301.13 243.04 342.78 243.04C362.74 243.04 381.67 235.16 390.62 220.09C398.19 236.87 414.37 243.72 433.3 242.01V242.01ZM405.77 51.26C420.57 51.26 431.58 40.64 431.58 25.92C431.58 13.59 424.01 4 409.9 4C395.1 4 384.09 14.62 384.09 29.34C384.09 41.67 391.66 51.26 405.77 51.26ZM338.66 51.26C353.46 51.26 364.47 40.64 364.47 25.92C364.47 13.59 356.9 4 342.79 4C328.68 4 316.98 14.62 316.98 29.34C316.98 41.67 324.55 51.26 338.66 51.26Z" />
                <path d="M515.69 193.04L540.13 170.09L575.92 238.93H622.04L569.73 142.01L638.56 76.6H582.8L523.26 133.79L538.06 13.58H497.45L469.57 238.92H510.18L515.69 193.03V193.04Z" />
                <path d="M705.49 76.6H665.57L645.61 238.93H686.22L696.89 151.6C699.99 125.57 716.16 111.53 734.75 111.53C749.89 111.53 757.12 120.43 757.12 138.93C757.12 143.72 756.43 148.18 756.09 151.94L745.42 238.93H786.03L797.39 147.15C798.08 141.33 798.42 135.51 798.42 130.37C798.42 96.12 781.21 72.49 745.76 72.49C728.55 72.49 712.72 77.97 703.77 89.96L705.49 76.6V76.6Z" />
                <path d="M872.09 140.64C876.57 120.43 890.33 108.45 907.2 108.45C925.44 108.45 934.05 122.49 934.05 140.64H872.1H872.09ZM868.31 173.52H971.22C973.63 164.96 975.01 155.71 975.01 145.78C975.01 108.79 956.08 72.49 909.96 72.49C863.84 72.49 827.01 112.9 827.01 164.61C827.01 205.02 847.32 243.04 899.97 243.04C919.93 243.04 939.55 237.56 957.45 226.94L939.55 192.69C926.47 201.25 913.05 205.02 900.66 205.02C876.91 205.02 868.65 190.98 868.31 173.51V173.52Z" />
              </g>
            </svg>
          </Link>
          <p className="dimmed">Brand Guidelines / Guidelines</p>
        </div>
        <div className="page-content">
          <h1>Guidelines</h1>
        </div>
        <div className="page-footer">
          <Link to="#audience">
            <h1>&larr;</h1>
          </Link>
          <Link to="#voice">
            <h1>&rarr;</h1>
          </Link>
        </div>
      </section>
      <section id="voice" className="page white split">
        <div className="page-header">
          <Link to="#index">
            <svg
              viewBox="0 0 980 308"
              xmlns="http://www.w3.org/2000/svg"
              fillRule="evenodd"
            >
              <g >
                <path d="M108.97 225.57L107.25 238.93H146.83L174.71 13.59H134.1L124.81 88.93C115.52 77.97 99.69 72.49 82.48 72.49C37.04 72.49 4 110.85 4 163.93C4 208.11 26.72 243.04 67.67 243.04C84.53 243.04 100.37 237.22 108.97 225.57V225.57ZM76.62 204C55.63 204 45.64 186.88 45.64 162.9C45.64 131.39 62.5 111.53 85.91 111.53C107.59 111.53 117.57 128.65 117.57 151.6C117.57 182.42 100.02 204 76.61 204H76.62Z" />
                <path d="M242.52 51.26C228.41 51.26 220.49 41.67 220.49 29.34C220.49 14.61 231.85 4 246.65 4C261.45 4 268.68 13.59 268.68 25.92C268.68 40.65 257.32 51.26 242.52 51.26ZM177.47 304C211.89 304 233.57 282.08 238.04 244.75L258.69 76.6H218.08L197.77 240.98C195.7 257.42 188.13 264.61 173.33 264.61C167.82 264.61 162.66 263.58 157.5 261.87L147.86 297.49C157.5 301.94 167.13 304 177.46 304H177.47Z" />
                <path d="M351.39 204C330.39 204 320.41 186.88 320.41 162.9C320.41 131.39 337.27 111.53 360.68 111.53C382.36 111.53 392.34 128.65 392.34 151.6C392.34 182.42 374.79 204 351.38 204H351.39ZM433.3 242.01L441.56 209.13C431.24 208.1 427.79 202.62 427.79 193.38C427.79 189.27 428.48 183.45 429.17 178.31L441.56 76.6H401.29L399.91 89.27C390.62 77.97 374.44 72.49 357.23 72.49C311.8 72.49 278.76 110.85 278.76 164.27C278.76 208.11 301.13 243.04 342.78 243.04C362.74 243.04 381.67 235.16 390.62 220.09C398.19 236.87 414.37 243.72 433.3 242.01V242.01ZM405.77 51.26C420.57 51.26 431.58 40.64 431.58 25.92C431.58 13.59 424.01 4 409.9 4C395.1 4 384.09 14.62 384.09 29.34C384.09 41.67 391.66 51.26 405.77 51.26ZM338.66 51.26C353.46 51.26 364.47 40.64 364.47 25.92C364.47 13.59 356.9 4 342.79 4C328.68 4 316.98 14.62 316.98 29.34C316.98 41.67 324.55 51.26 338.66 51.26Z" />
                <path d="M515.69 193.04L540.13 170.09L575.92 238.93H622.04L569.73 142.01L638.56 76.6H582.8L523.26 133.79L538.06 13.58H497.45L469.57 238.92H510.18L515.69 193.03V193.04Z" />
                <path d="M705.49 76.6H665.57L645.61 238.93H686.22L696.89 151.6C699.99 125.57 716.16 111.53 734.75 111.53C749.89 111.53 757.12 120.43 757.12 138.93C757.12 143.72 756.43 148.18 756.09 151.94L745.42 238.93H786.03L797.39 147.15C798.08 141.33 798.42 135.51 798.42 130.37C798.42 96.12 781.21 72.49 745.76 72.49C728.55 72.49 712.72 77.97 703.77 89.96L705.49 76.6V76.6Z" />
                <path d="M872.09 140.64C876.57 120.43 890.33 108.45 907.2 108.45C925.44 108.45 934.05 122.49 934.05 140.64H872.1H872.09ZM868.31 173.52H971.22C973.63 164.96 975.01 155.71 975.01 145.78C975.01 108.79 956.08 72.49 909.96 72.49C863.84 72.49 827.01 112.9 827.01 164.61C827.01 205.02 847.32 243.04 899.97 243.04C919.93 243.04 939.55 237.56 957.45 226.94L939.55 192.69C926.47 201.25 913.05 205.02 900.66 205.02C876.91 205.02 868.65 190.98 868.31 173.51V173.52Z" />
              </g>
            </svg>
          </Link>
          <p className="dimmed">Brand Guidelines / Guidelines</p>
        </div>
        <div className="page-content ">
          <div className="left">
            <div className="text-container">
              <h2>
                The voice
              </h2>
            </div>
          </div>
          <div className="right">
            <div className="text-container">
              <h1 className="my-4">Feel at home</h1>
              <p>
                Our audience builds things – from scratch and fearlessly. They
                are capable people within their field of expertise. We stay
                humble and invite them to use us as a source of inspiration and
                knowledge – through ups and downs on the startup journey. By
                using a voice that is concise, frank and friendly, we build
                trust and make them feel at home.
              </p>
              <p>
                Being concise and frank is our way to spare the audience’s time
                and show that we know what we are talking about. At the same
                time, the voice is warm and joyful to enhance the values of
                startup life. We are all ordinary people with rather unordinary
                working lives. We let that show.
              </p>
            </div>
          </div>
        </div>
        <div className="page-footer">
          <Link to="#guidelines">
            <h1>&larr;</h1>
          </Link >
          <Link to="#logo-1">
            <h1>&rarr;</h1>
          </Link>
        </div>
      </section>
      <section id="logo-1" className="page white split">
        <div className="page-header">
          <Link to="#index">
            <svg
              viewBox="0 0 980 308"
              xmlns="http://www.w3.org/2000/svg"
              fillRule="evenodd"
            >
              <g >
                <path d="M108.97 225.57L107.25 238.93H146.83L174.71 13.59H134.1L124.81 88.93C115.52 77.97 99.69 72.49 82.48 72.49C37.04 72.49 4 110.85 4 163.93C4 208.11 26.72 243.04 67.67 243.04C84.53 243.04 100.37 237.22 108.97 225.57V225.57ZM76.62 204C55.63 204 45.64 186.88 45.64 162.9C45.64 131.39 62.5 111.53 85.91 111.53C107.59 111.53 117.57 128.65 117.57 151.6C117.57 182.42 100.02 204 76.61 204H76.62Z" />
                <path d="M242.52 51.26C228.41 51.26 220.49 41.67 220.49 29.34C220.49 14.61 231.85 4 246.65 4C261.45 4 268.68 13.59 268.68 25.92C268.68 40.65 257.32 51.26 242.52 51.26ZM177.47 304C211.89 304 233.57 282.08 238.04 244.75L258.69 76.6H218.08L197.77 240.98C195.7 257.42 188.13 264.61 173.33 264.61C167.82 264.61 162.66 263.58 157.5 261.87L147.86 297.49C157.5 301.94 167.13 304 177.46 304H177.47Z" />
                <path d="M351.39 204C330.39 204 320.41 186.88 320.41 162.9C320.41 131.39 337.27 111.53 360.68 111.53C382.36 111.53 392.34 128.65 392.34 151.6C392.34 182.42 374.79 204 351.38 204H351.39ZM433.3 242.01L441.56 209.13C431.24 208.1 427.79 202.62 427.79 193.38C427.79 189.27 428.48 183.45 429.17 178.31L441.56 76.6H401.29L399.91 89.27C390.62 77.97 374.44 72.49 357.23 72.49C311.8 72.49 278.76 110.85 278.76 164.27C278.76 208.11 301.13 243.04 342.78 243.04C362.74 243.04 381.67 235.16 390.62 220.09C398.19 236.87 414.37 243.72 433.3 242.01V242.01ZM405.77 51.26C420.57 51.26 431.58 40.64 431.58 25.92C431.58 13.59 424.01 4 409.9 4C395.1 4 384.09 14.62 384.09 29.34C384.09 41.67 391.66 51.26 405.77 51.26ZM338.66 51.26C353.46 51.26 364.47 40.64 364.47 25.92C364.47 13.59 356.9 4 342.79 4C328.68 4 316.98 14.62 316.98 29.34C316.98 41.67 324.55 51.26 338.66 51.26Z" />
                <path d="M515.69 193.04L540.13 170.09L575.92 238.93H622.04L569.73 142.01L638.56 76.6H582.8L523.26 133.79L538.06 13.58H497.45L469.57 238.92H510.18L515.69 193.03V193.04Z" />
                <path d="M705.49 76.6H665.57L645.61 238.93H686.22L696.89 151.6C699.99 125.57 716.16 111.53 734.75 111.53C749.89 111.53 757.12 120.43 757.12 138.93C757.12 143.72 756.43 148.18 756.09 151.94L745.42 238.93H786.03L797.39 147.15C798.08 141.33 798.42 135.51 798.42 130.37C798.42 96.12 781.21 72.49 745.76 72.49C728.55 72.49 712.72 77.97 703.77 89.96L705.49 76.6V76.6Z" />
                <path d="M872.09 140.64C876.57 120.43 890.33 108.45 907.2 108.45C925.44 108.45 934.05 122.49 934.05 140.64H872.1H872.09ZM868.31 173.52H971.22C973.63 164.96 975.01 155.71 975.01 145.78C975.01 108.79 956.08 72.49 909.96 72.49C863.84 72.49 827.01 112.9 827.01 164.61C827.01 205.02 847.32 243.04 899.97 243.04C919.93 243.04 939.55 237.56 957.45 226.94L939.55 192.69C926.47 201.25 913.05 205.02 900.66 205.02C876.91 205.02 868.65 190.98 868.31 173.51V173.52Z" />
              </g>
            </svg>
          </Link>
          <p className="dimmed">Brand Guidelines / Guidelines</p>
        </div>
        <div className="page-content ">
          <div className="left">
            <div className="text-container mb-5">
              <h2>
                Logotype
              </h2>
              <p className="text-md-center"><small>(1/3)</small></p>
            </div>
          </div>
          <div className="right">
            <div className="text-container">
              <svg
                className="mb-5 logo"
                viewBox="0 0 980 308"
                xmlns="http://www.w3.org/2000/svg"
                fillRule="evenodd"
              >
                <g >
                  <path d="M108.97 225.57L107.25 238.93H146.83L174.71 13.59H134.1L124.81 88.93C115.52 77.97 99.69 72.49 82.48 72.49C37.04 72.49 4 110.85 4 163.93C4 208.11 26.72 243.04 67.67 243.04C84.53 243.04 100.37 237.22 108.97 225.57V225.57ZM76.62 204C55.63 204 45.64 186.88 45.64 162.9C45.64 131.39 62.5 111.53 85.91 111.53C107.59 111.53 117.57 128.65 117.57 151.6C117.57 182.42 100.02 204 76.61 204H76.62Z" />
                  <path d="M242.52 51.26C228.41 51.26 220.49 41.67 220.49 29.34C220.49 14.61 231.85 4 246.65 4C261.45 4 268.68 13.59 268.68 25.92C268.68 40.65 257.32 51.26 242.52 51.26ZM177.47 304C211.89 304 233.57 282.08 238.04 244.75L258.69 76.6H218.08L197.77 240.98C195.7 257.42 188.13 264.61 173.33 264.61C167.82 264.61 162.66 263.58 157.5 261.87L147.86 297.49C157.5 301.94 167.13 304 177.46 304H177.47Z" />
                  <path d="M351.39 204C330.39 204 320.41 186.88 320.41 162.9C320.41 131.39 337.27 111.53 360.68 111.53C382.36 111.53 392.34 128.65 392.34 151.6C392.34 182.42 374.79 204 351.38 204H351.39ZM433.3 242.01L441.56 209.13C431.24 208.1 427.79 202.62 427.79 193.38C427.79 189.27 428.48 183.45 429.17 178.31L441.56 76.6H401.29L399.91 89.27C390.62 77.97 374.44 72.49 357.23 72.49C311.8 72.49 278.76 110.85 278.76 164.27C278.76 208.11 301.13 243.04 342.78 243.04C362.74 243.04 381.67 235.16 390.62 220.09C398.19 236.87 414.37 243.72 433.3 242.01V242.01ZM405.77 51.26C420.57 51.26 431.58 40.64 431.58 25.92C431.58 13.59 424.01 4 409.9 4C395.1 4 384.09 14.62 384.09 29.34C384.09 41.67 391.66 51.26 405.77 51.26ZM338.66 51.26C353.46 51.26 364.47 40.64 364.47 25.92C364.47 13.59 356.9 4 342.79 4C328.68 4 316.98 14.62 316.98 29.34C316.98 41.67 324.55 51.26 338.66 51.26Z" />
                  <path d="M515.69 193.04L540.13 170.09L575.92 238.93H622.04L569.73 142.01L638.56 76.6H582.8L523.26 133.79L538.06 13.58H497.45L469.57 238.92H510.18L515.69 193.03V193.04Z" />
                  <path d="M705.49 76.6H665.57L645.61 238.93H686.22L696.89 151.6C699.99 125.57 716.16 111.53 734.75 111.53C749.89 111.53 757.12 120.43 757.12 138.93C757.12 143.72 756.43 148.18 756.09 151.94L745.42 238.93H786.03L797.39 147.15C798.08 141.33 798.42 135.51 798.42 130.37C798.42 96.12 781.21 72.49 745.76 72.49C728.55 72.49 712.72 77.97 703.77 89.96L705.49 76.6V76.6Z" />
                  <path d="M872.09 140.64C876.57 120.43 890.33 108.45 907.2 108.45C925.44 108.45 934.05 122.49 934.05 140.64H872.1H872.09ZM868.31 173.52H971.22C973.63 164.96 975.01 155.71 975.01 145.78C975.01 108.79 956.08 72.49 909.96 72.49C863.84 72.49 827.01 112.9 827.01 164.61C827.01 205.02 847.32 243.04 899.97 243.04C919.93 243.04 939.55 237.56 957.45 226.94L939.55 192.69C926.47 201.25 913.05 205.02 900.66 205.02C876.91 205.02 868.65 190.98 868.31 173.51V173.52Z" />
                </g>
              </svg>
              <h4>Logo rationale</h4>
              <p>The name djäkne has been established and is now one with our identity, hence we only use a wordmark for purposes of identification and branding.</p>
              <p>The font has been chosen with clarity and balance in mind. Menca font takes cues from the lettering of engineering, found on road signage and industrial metal plaques. It is a font that is calm, clear and of high quality in every detail.</p>
              <p>By using the italic typeface the wordmark gets a clear direction forward. The bold typeface makes the logotype work both in small and large format.</p>
            </div>
          </div>
        </div>
        <div className="page-footer">
          <Link to="#voice">
            <h1>&larr;</h1>
          </Link >
          <Link to="#logo-2">
            <h1>&rarr;</h1>
          </Link>
        </div>
      </section>
      <section id="logo-2" className="page white split">
        <div className="page-header">
          <Link to="#index">
            <svg
              viewBox="0 0 980 308"
              xmlns="http://www.w3.org/2000/svg"
              fillRule="evenodd"
            >
              <g >
                <path d="M108.97 225.57L107.25 238.93H146.83L174.71 13.59H134.1L124.81 88.93C115.52 77.97 99.69 72.49 82.48 72.49C37.04 72.49 4 110.85 4 163.93C4 208.11 26.72 243.04 67.67 243.04C84.53 243.04 100.37 237.22 108.97 225.57V225.57ZM76.62 204C55.63 204 45.64 186.88 45.64 162.9C45.64 131.39 62.5 111.53 85.91 111.53C107.59 111.53 117.57 128.65 117.57 151.6C117.57 182.42 100.02 204 76.61 204H76.62Z" />
                <path d="M242.52 51.26C228.41 51.26 220.49 41.67 220.49 29.34C220.49 14.61 231.85 4 246.65 4C261.45 4 268.68 13.59 268.68 25.92C268.68 40.65 257.32 51.26 242.52 51.26ZM177.47 304C211.89 304 233.57 282.08 238.04 244.75L258.69 76.6H218.08L197.77 240.98C195.7 257.42 188.13 264.61 173.33 264.61C167.82 264.61 162.66 263.58 157.5 261.87L147.86 297.49C157.5 301.94 167.13 304 177.46 304H177.47Z" />
                <path d="M351.39 204C330.39 204 320.41 186.88 320.41 162.9C320.41 131.39 337.27 111.53 360.68 111.53C382.36 111.53 392.34 128.65 392.34 151.6C392.34 182.42 374.79 204 351.38 204H351.39ZM433.3 242.01L441.56 209.13C431.24 208.1 427.79 202.62 427.79 193.38C427.79 189.27 428.48 183.45 429.17 178.31L441.56 76.6H401.29L399.91 89.27C390.62 77.97 374.44 72.49 357.23 72.49C311.8 72.49 278.76 110.85 278.76 164.27C278.76 208.11 301.13 243.04 342.78 243.04C362.74 243.04 381.67 235.16 390.62 220.09C398.19 236.87 414.37 243.72 433.3 242.01V242.01ZM405.77 51.26C420.57 51.26 431.58 40.64 431.58 25.92C431.58 13.59 424.01 4 409.9 4C395.1 4 384.09 14.62 384.09 29.34C384.09 41.67 391.66 51.26 405.77 51.26ZM338.66 51.26C353.46 51.26 364.47 40.64 364.47 25.92C364.47 13.59 356.9 4 342.79 4C328.68 4 316.98 14.62 316.98 29.34C316.98 41.67 324.55 51.26 338.66 51.26Z" />
                <path d="M515.69 193.04L540.13 170.09L575.92 238.93H622.04L569.73 142.01L638.56 76.6H582.8L523.26 133.79L538.06 13.58H497.45L469.57 238.92H510.18L515.69 193.03V193.04Z" />
                <path d="M705.49 76.6H665.57L645.61 238.93H686.22L696.89 151.6C699.99 125.57 716.16 111.53 734.75 111.53C749.89 111.53 757.12 120.43 757.12 138.93C757.12 143.72 756.43 148.18 756.09 151.94L745.42 238.93H786.03L797.39 147.15C798.08 141.33 798.42 135.51 798.42 130.37C798.42 96.12 781.21 72.49 745.76 72.49C728.55 72.49 712.72 77.97 703.77 89.96L705.49 76.6V76.6Z" />
                <path d="M872.09 140.64C876.57 120.43 890.33 108.45 907.2 108.45C925.44 108.45 934.05 122.49 934.05 140.64H872.1H872.09ZM868.31 173.52H971.22C973.63 164.96 975.01 155.71 975.01 145.78C975.01 108.79 956.08 72.49 909.96 72.49C863.84 72.49 827.01 112.9 827.01 164.61C827.01 205.02 847.32 243.04 899.97 243.04C919.93 243.04 939.55 237.56 957.45 226.94L939.55 192.69C926.47 201.25 913.05 205.02 900.66 205.02C876.91 205.02 868.65 190.98 868.31 173.51V173.52Z" />
              </g>
            </svg>
          </Link>
          <p className="dimmed">Brand Guidelines / Guidelines</p>
        </div>
        <div className="page-content ">
          <div className="left">
            <div className="text-container mb-5">
              <h2>
                Logotype
              </h2>
              <p className="text-md-center"><small>(2/3)</small></p>
            </div>
          </div>
          <div className="right">
            <div className="text-container">

              <h4>Variants and colors</h4>
              <p className="pb-4">The logo only exist in one variant and is available in two colors djäkne-white and djäkne-black. Choose the version that gives the best contrast in relation to the background. In cases where readability and brand visibility is not a primary goal the logo can be used on similar color background to hightlight or "black on black" vision.</p>
              <div className=" logo-variants-container">
                <div>
                  <svg
                    className="logo"
                    viewBox="0 0 980 308"
                    xmlns="http://www.w3.org/2000/svg"
                    fillRule="evenodd"
                  >
                    <g >
                      <path d="M108.97 225.57L107.25 238.93H146.83L174.71 13.59H134.1L124.81 88.93C115.52 77.97 99.69 72.49 82.48 72.49C37.04 72.49 4 110.85 4 163.93C4 208.11 26.72 243.04 67.67 243.04C84.53 243.04 100.37 237.22 108.97 225.57V225.57ZM76.62 204C55.63 204 45.64 186.88 45.64 162.9C45.64 131.39 62.5 111.53 85.91 111.53C107.59 111.53 117.57 128.65 117.57 151.6C117.57 182.42 100.02 204 76.61 204H76.62Z" />
                      <path d="M242.52 51.26C228.41 51.26 220.49 41.67 220.49 29.34C220.49 14.61 231.85 4 246.65 4C261.45 4 268.68 13.59 268.68 25.92C268.68 40.65 257.32 51.26 242.52 51.26ZM177.47 304C211.89 304 233.57 282.08 238.04 244.75L258.69 76.6H218.08L197.77 240.98C195.7 257.42 188.13 264.61 173.33 264.61C167.82 264.61 162.66 263.58 157.5 261.87L147.86 297.49C157.5 301.94 167.13 304 177.46 304H177.47Z" />
                      <path d="M351.39 204C330.39 204 320.41 186.88 320.41 162.9C320.41 131.39 337.27 111.53 360.68 111.53C382.36 111.53 392.34 128.65 392.34 151.6C392.34 182.42 374.79 204 351.38 204H351.39ZM433.3 242.01L441.56 209.13C431.24 208.1 427.79 202.62 427.79 193.38C427.79 189.27 428.48 183.45 429.17 178.31L441.56 76.6H401.29L399.91 89.27C390.62 77.97 374.44 72.49 357.23 72.49C311.8 72.49 278.76 110.85 278.76 164.27C278.76 208.11 301.13 243.04 342.78 243.04C362.74 243.04 381.67 235.16 390.62 220.09C398.19 236.87 414.37 243.72 433.3 242.01V242.01ZM405.77 51.26C420.57 51.26 431.58 40.64 431.58 25.92C431.58 13.59 424.01 4 409.9 4C395.1 4 384.09 14.62 384.09 29.34C384.09 41.67 391.66 51.26 405.77 51.26ZM338.66 51.26C353.46 51.26 364.47 40.64 364.47 25.92C364.47 13.59 356.9 4 342.79 4C328.68 4 316.98 14.62 316.98 29.34C316.98 41.67 324.55 51.26 338.66 51.26Z" />
                      <path d="M515.69 193.04L540.13 170.09L575.92 238.93H622.04L569.73 142.01L638.56 76.6H582.8L523.26 133.79L538.06 13.58H497.45L469.57 238.92H510.18L515.69 193.03V193.04Z" />
                      <path d="M705.49 76.6H665.57L645.61 238.93H686.22L696.89 151.6C699.99 125.57 716.16 111.53 734.75 111.53C749.89 111.53 757.12 120.43 757.12 138.93C757.12 143.72 756.43 148.18 756.09 151.94L745.42 238.93H786.03L797.39 147.15C798.08 141.33 798.42 135.51 798.42 130.37C798.42 96.12 781.21 72.49 745.76 72.49C728.55 72.49 712.72 77.97 703.77 89.96L705.49 76.6V76.6Z" />
                      <path d="M872.09 140.64C876.57 120.43 890.33 108.45 907.2 108.45C925.44 108.45 934.05 122.49 934.05 140.64H872.1H872.09ZM868.31 173.52H971.22C973.63 164.96 975.01 155.71 975.01 145.78C975.01 108.79 956.08 72.49 909.96 72.49C863.84 72.49 827.01 112.9 827.01 164.61C827.01 205.02 847.32 243.04 899.97 243.04C919.93 243.04 939.55 237.56 957.45 226.94L939.55 192.69C926.47 201.25 913.05 205.02 900.66 205.02C876.91 205.02 868.65 190.98 868.31 173.51V173.52Z" />
                    </g>
                  </svg>
                </div>
                <div>

                  <svg
                    className="logo"
                    viewBox="0 0 980 308"
                    xmlns="http://www.w3.org/2000/svg"
                    fillRule="evenodd"
                  >
                    <g >
                      <path d="M108.97 225.57L107.25 238.93H146.83L174.71 13.59H134.1L124.81 88.93C115.52 77.97 99.69 72.49 82.48 72.49C37.04 72.49 4 110.85 4 163.93C4 208.11 26.72 243.04 67.67 243.04C84.53 243.04 100.37 237.22 108.97 225.57V225.57ZM76.62 204C55.63 204 45.64 186.88 45.64 162.9C45.64 131.39 62.5 111.53 85.91 111.53C107.59 111.53 117.57 128.65 117.57 151.6C117.57 182.42 100.02 204 76.61 204H76.62Z" />
                      <path d="M242.52 51.26C228.41 51.26 220.49 41.67 220.49 29.34C220.49 14.61 231.85 4 246.65 4C261.45 4 268.68 13.59 268.68 25.92C268.68 40.65 257.32 51.26 242.52 51.26ZM177.47 304C211.89 304 233.57 282.08 238.04 244.75L258.69 76.6H218.08L197.77 240.98C195.7 257.42 188.13 264.61 173.33 264.61C167.82 264.61 162.66 263.58 157.5 261.87L147.86 297.49C157.5 301.94 167.13 304 177.46 304H177.47Z" />
                      <path d="M351.39 204C330.39 204 320.41 186.88 320.41 162.9C320.41 131.39 337.27 111.53 360.68 111.53C382.36 111.53 392.34 128.65 392.34 151.6C392.34 182.42 374.79 204 351.38 204H351.39ZM433.3 242.01L441.56 209.13C431.24 208.1 427.79 202.62 427.79 193.38C427.79 189.27 428.48 183.45 429.17 178.31L441.56 76.6H401.29L399.91 89.27C390.62 77.97 374.44 72.49 357.23 72.49C311.8 72.49 278.76 110.85 278.76 164.27C278.76 208.11 301.13 243.04 342.78 243.04C362.74 243.04 381.67 235.16 390.62 220.09C398.19 236.87 414.37 243.72 433.3 242.01V242.01ZM405.77 51.26C420.57 51.26 431.58 40.64 431.58 25.92C431.58 13.59 424.01 4 409.9 4C395.1 4 384.09 14.62 384.09 29.34C384.09 41.67 391.66 51.26 405.77 51.26ZM338.66 51.26C353.46 51.26 364.47 40.64 364.47 25.92C364.47 13.59 356.9 4 342.79 4C328.68 4 316.98 14.62 316.98 29.34C316.98 41.67 324.55 51.26 338.66 51.26Z" />
                      <path d="M515.69 193.04L540.13 170.09L575.92 238.93H622.04L569.73 142.01L638.56 76.6H582.8L523.26 133.79L538.06 13.58H497.45L469.57 238.92H510.18L515.69 193.03V193.04Z" />
                      <path d="M705.49 76.6H665.57L645.61 238.93H686.22L696.89 151.6C699.99 125.57 716.16 111.53 734.75 111.53C749.89 111.53 757.12 120.43 757.12 138.93C757.12 143.72 756.43 148.18 756.09 151.94L745.42 238.93H786.03L797.39 147.15C798.08 141.33 798.42 135.51 798.42 130.37C798.42 96.12 781.21 72.49 745.76 72.49C728.55 72.49 712.72 77.97 703.77 89.96L705.49 76.6V76.6Z" />
                      <path d="M872.09 140.64C876.57 120.43 890.33 108.45 907.2 108.45C925.44 108.45 934.05 122.49 934.05 140.64H872.1H872.09ZM868.31 173.52H971.22C973.63 164.96 975.01 155.71 975.01 145.78C975.01 108.79 956.08 72.49 909.96 72.49C863.84 72.49 827.01 112.9 827.01 164.61C827.01 205.02 847.32 243.04 899.97 243.04C919.93 243.04 939.55 237.56 957.45 226.94L939.55 192.69C926.47 201.25 913.05 205.02 900.66 205.02C876.91 205.02 868.65 190.98 868.31 173.51V173.52Z" />
                    </g>
                  </svg>
                </div>
              </div>
              <div className="mb-4 logo-variants-container bob">
                <div>
                  <svg
                    className="logo"
                    viewBox="0 0 980 308"
                    xmlns="http://www.w3.org/2000/svg"
                    fillRule="evenodd"
                  >
                    <g >
                      <path d="M108.97 225.57L107.25 238.93H146.83L174.71 13.59H134.1L124.81 88.93C115.52 77.97 99.69 72.49 82.48 72.49C37.04 72.49 4 110.85 4 163.93C4 208.11 26.72 243.04 67.67 243.04C84.53 243.04 100.37 237.22 108.97 225.57V225.57ZM76.62 204C55.63 204 45.64 186.88 45.64 162.9C45.64 131.39 62.5 111.53 85.91 111.53C107.59 111.53 117.57 128.65 117.57 151.6C117.57 182.42 100.02 204 76.61 204H76.62Z" />
                      <path d="M242.52 51.26C228.41 51.26 220.49 41.67 220.49 29.34C220.49 14.61 231.85 4 246.65 4C261.45 4 268.68 13.59 268.68 25.92C268.68 40.65 257.32 51.26 242.52 51.26ZM177.47 304C211.89 304 233.57 282.08 238.04 244.75L258.69 76.6H218.08L197.77 240.98C195.7 257.42 188.13 264.61 173.33 264.61C167.82 264.61 162.66 263.58 157.5 261.87L147.86 297.49C157.5 301.94 167.13 304 177.46 304H177.47Z" />
                      <path d="M351.39 204C330.39 204 320.41 186.88 320.41 162.9C320.41 131.39 337.27 111.53 360.68 111.53C382.36 111.53 392.34 128.65 392.34 151.6C392.34 182.42 374.79 204 351.38 204H351.39ZM433.3 242.01L441.56 209.13C431.24 208.1 427.79 202.62 427.79 193.38C427.79 189.27 428.48 183.45 429.17 178.31L441.56 76.6H401.29L399.91 89.27C390.62 77.97 374.44 72.49 357.23 72.49C311.8 72.49 278.76 110.85 278.76 164.27C278.76 208.11 301.13 243.04 342.78 243.04C362.74 243.04 381.67 235.16 390.62 220.09C398.19 236.87 414.37 243.72 433.3 242.01V242.01ZM405.77 51.26C420.57 51.26 431.58 40.64 431.58 25.92C431.58 13.59 424.01 4 409.9 4C395.1 4 384.09 14.62 384.09 29.34C384.09 41.67 391.66 51.26 405.77 51.26ZM338.66 51.26C353.46 51.26 364.47 40.64 364.47 25.92C364.47 13.59 356.9 4 342.79 4C328.68 4 316.98 14.62 316.98 29.34C316.98 41.67 324.55 51.26 338.66 51.26Z" />
                      <path d="M515.69 193.04L540.13 170.09L575.92 238.93H622.04L569.73 142.01L638.56 76.6H582.8L523.26 133.79L538.06 13.58H497.45L469.57 238.92H510.18L515.69 193.03V193.04Z" />
                      <path d="M705.49 76.6H665.57L645.61 238.93H686.22L696.89 151.6C699.99 125.57 716.16 111.53 734.75 111.53C749.89 111.53 757.12 120.43 757.12 138.93C757.12 143.72 756.43 148.18 756.09 151.94L745.42 238.93H786.03L797.39 147.15C798.08 141.33 798.42 135.51 798.42 130.37C798.42 96.12 781.21 72.49 745.76 72.49C728.55 72.49 712.72 77.97 703.77 89.96L705.49 76.6V76.6Z" />
                      <path d="M872.09 140.64C876.57 120.43 890.33 108.45 907.2 108.45C925.44 108.45 934.05 122.49 934.05 140.64H872.1H872.09ZM868.31 173.52H971.22C973.63 164.96 975.01 155.71 975.01 145.78C975.01 108.79 956.08 72.49 909.96 72.49C863.84 72.49 827.01 112.9 827.01 164.61C827.01 205.02 847.32 243.04 899.97 243.04C919.93 243.04 939.55 237.56 957.45 226.94L939.55 192.69C926.47 201.25 913.05 205.02 900.66 205.02C876.91 205.02 868.65 190.98 868.31 173.51V173.52Z" />
                    </g>
                  </svg>
                </div>
                <div>
                  <svg
                    className="logo"
                    viewBox="0 0 980 308"
                    xmlns="http://www.w3.org/2000/svg"
                    fillRule="evenodd"
                  >
                    <g >
                      <path d="M108.97 225.57L107.25 238.93H146.83L174.71 13.59H134.1L124.81 88.93C115.52 77.97 99.69 72.49 82.48 72.49C37.04 72.49 4 110.85 4 163.93C4 208.11 26.72 243.04 67.67 243.04C84.53 243.04 100.37 237.22 108.97 225.57V225.57ZM76.62 204C55.63 204 45.64 186.88 45.64 162.9C45.64 131.39 62.5 111.53 85.91 111.53C107.59 111.53 117.57 128.65 117.57 151.6C117.57 182.42 100.02 204 76.61 204H76.62Z" />
                      <path d="M242.52 51.26C228.41 51.26 220.49 41.67 220.49 29.34C220.49 14.61 231.85 4 246.65 4C261.45 4 268.68 13.59 268.68 25.92C268.68 40.65 257.32 51.26 242.52 51.26ZM177.47 304C211.89 304 233.57 282.08 238.04 244.75L258.69 76.6H218.08L197.77 240.98C195.7 257.42 188.13 264.61 173.33 264.61C167.82 264.61 162.66 263.58 157.5 261.87L147.86 297.49C157.5 301.94 167.13 304 177.46 304H177.47Z" />
                      <path d="M351.39 204C330.39 204 320.41 186.88 320.41 162.9C320.41 131.39 337.27 111.53 360.68 111.53C382.36 111.53 392.34 128.65 392.34 151.6C392.34 182.42 374.79 204 351.38 204H351.39ZM433.3 242.01L441.56 209.13C431.24 208.1 427.79 202.62 427.79 193.38C427.79 189.27 428.48 183.45 429.17 178.31L441.56 76.6H401.29L399.91 89.27C390.62 77.97 374.44 72.49 357.23 72.49C311.8 72.49 278.76 110.85 278.76 164.27C278.76 208.11 301.13 243.04 342.78 243.04C362.74 243.04 381.67 235.16 390.62 220.09C398.19 236.87 414.37 243.72 433.3 242.01V242.01ZM405.77 51.26C420.57 51.26 431.58 40.64 431.58 25.92C431.58 13.59 424.01 4 409.9 4C395.1 4 384.09 14.62 384.09 29.34C384.09 41.67 391.66 51.26 405.77 51.26ZM338.66 51.26C353.46 51.26 364.47 40.64 364.47 25.92C364.47 13.59 356.9 4 342.79 4C328.68 4 316.98 14.62 316.98 29.34C316.98 41.67 324.55 51.26 338.66 51.26Z" />
                      <path d="M515.69 193.04L540.13 170.09L575.92 238.93H622.04L569.73 142.01L638.56 76.6H582.8L523.26 133.79L538.06 13.58H497.45L469.57 238.92H510.18L515.69 193.03V193.04Z" />
                      <path d="M705.49 76.6H665.57L645.61 238.93H686.22L696.89 151.6C699.99 125.57 716.16 111.53 734.75 111.53C749.89 111.53 757.12 120.43 757.12 138.93C757.12 143.72 756.43 148.18 756.09 151.94L745.42 238.93H786.03L797.39 147.15C798.08 141.33 798.42 135.51 798.42 130.37C798.42 96.12 781.21 72.49 745.76 72.49C728.55 72.49 712.72 77.97 703.77 89.96L705.49 76.6V76.6Z" />
                      <path d="M872.09 140.64C876.57 120.43 890.33 108.45 907.2 108.45C925.44 108.45 934.05 122.49 934.05 140.64H872.1H872.09ZM868.31 173.52H971.22C973.63 164.96 975.01 155.71 975.01 145.78C975.01 108.79 956.08 72.49 909.96 72.49C863.84 72.49 827.01 112.9 827.01 164.61C827.01 205.02 847.32 243.04 899.97 243.04C919.93 243.04 939.55 237.56 957.45 226.94L939.55 192.69C926.47 201.25 913.05 205.02 900.66 205.02C876.91 205.02 868.65 190.98 868.31 173.51V173.52Z" />
                    </g>
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="page-footer">
          <Link to="#logo-1">
            <h1>&larr;</h1>
          </Link >
          <Link to="#logo-3">
            <h1>&rarr;</h1>
          </Link>
        </div>
      </section>
      <section id="logo-3" className="page white split">
        <div className="page-header">
          <Link to="#index">
            <svg
              viewBox="0 0 980 308"
              xmlns="http://www.w3.org/2000/svg"
              fillRule="evenodd"
            >
              <g >
                <path d="M108.97 225.57L107.25 238.93H146.83L174.71 13.59H134.1L124.81 88.93C115.52 77.97 99.69 72.49 82.48 72.49C37.04 72.49 4 110.85 4 163.93C4 208.11 26.72 243.04 67.67 243.04C84.53 243.04 100.37 237.22 108.97 225.57V225.57ZM76.62 204C55.63 204 45.64 186.88 45.64 162.9C45.64 131.39 62.5 111.53 85.91 111.53C107.59 111.53 117.57 128.65 117.57 151.6C117.57 182.42 100.02 204 76.61 204H76.62Z" />
                <path d="M242.52 51.26C228.41 51.26 220.49 41.67 220.49 29.34C220.49 14.61 231.85 4 246.65 4C261.45 4 268.68 13.59 268.68 25.92C268.68 40.65 257.32 51.26 242.52 51.26ZM177.47 304C211.89 304 233.57 282.08 238.04 244.75L258.69 76.6H218.08L197.77 240.98C195.7 257.42 188.13 264.61 173.33 264.61C167.82 264.61 162.66 263.58 157.5 261.87L147.86 297.49C157.5 301.94 167.13 304 177.46 304H177.47Z" />
                <path d="M351.39 204C330.39 204 320.41 186.88 320.41 162.9C320.41 131.39 337.27 111.53 360.68 111.53C382.36 111.53 392.34 128.65 392.34 151.6C392.34 182.42 374.79 204 351.38 204H351.39ZM433.3 242.01L441.56 209.13C431.24 208.1 427.79 202.62 427.79 193.38C427.79 189.27 428.48 183.45 429.17 178.31L441.56 76.6H401.29L399.91 89.27C390.62 77.97 374.44 72.49 357.23 72.49C311.8 72.49 278.76 110.85 278.76 164.27C278.76 208.11 301.13 243.04 342.78 243.04C362.74 243.04 381.67 235.16 390.62 220.09C398.19 236.87 414.37 243.72 433.3 242.01V242.01ZM405.77 51.26C420.57 51.26 431.58 40.64 431.58 25.92C431.58 13.59 424.01 4 409.9 4C395.1 4 384.09 14.62 384.09 29.34C384.09 41.67 391.66 51.26 405.77 51.26ZM338.66 51.26C353.46 51.26 364.47 40.64 364.47 25.92C364.47 13.59 356.9 4 342.79 4C328.68 4 316.98 14.62 316.98 29.34C316.98 41.67 324.55 51.26 338.66 51.26Z" />
                <path d="M515.69 193.04L540.13 170.09L575.92 238.93H622.04L569.73 142.01L638.56 76.6H582.8L523.26 133.79L538.06 13.58H497.45L469.57 238.92H510.18L515.69 193.03V193.04Z" />
                <path d="M705.49 76.6H665.57L645.61 238.93H686.22L696.89 151.6C699.99 125.57 716.16 111.53 734.75 111.53C749.89 111.53 757.12 120.43 757.12 138.93C757.12 143.72 756.43 148.18 756.09 151.94L745.42 238.93H786.03L797.39 147.15C798.08 141.33 798.42 135.51 798.42 130.37C798.42 96.12 781.21 72.49 745.76 72.49C728.55 72.49 712.72 77.97 703.77 89.96L705.49 76.6V76.6Z" />
                <path d="M872.09 140.64C876.57 120.43 890.33 108.45 907.2 108.45C925.44 108.45 934.05 122.49 934.05 140.64H872.1H872.09ZM868.31 173.52H971.22C973.63 164.96 975.01 155.71 975.01 145.78C975.01 108.79 956.08 72.49 909.96 72.49C863.84 72.49 827.01 112.9 827.01 164.61C827.01 205.02 847.32 243.04 899.97 243.04C919.93 243.04 939.55 237.56 957.45 226.94L939.55 192.69C926.47 201.25 913.05 205.02 900.66 205.02C876.91 205.02 868.65 190.98 868.31 173.51V173.52Z" />
              </g>
            </svg>
          </Link>
          <p className="dimmed">Brand Guidelines / Guidelines</p>
        </div>
        <div className="page-content ">
          <div className="left">
            <div className="text-container mb-5">
              <h2>
                Logotype
              </h2>
              <p className="text-md-center"><small>(3/3)</small></p>
            </div>
          </div>
          <div className="right">
            <div className="text-container">
              <h4>Spacing</h4>
              <p>Use the logos height as a measuring reference. Divide the logos height into two parts, this value is the clearance or spacing that the logo needs from other elements. </p>
              <div className=" logo-variants-container">
                <div className="spacing-container">
                  <svg
                    className="logo spacing-bg"
                    viewBox="0 0 980 308"
                    xmlns="http://www.w3.org/2000/svg"
                    fillRule="evenodd"
                  >
                    <g >
                      <path d="M108.97 225.57L107.25 238.93H146.83L174.71 13.59H134.1L124.81 88.93C115.52 77.97 99.69 72.49 82.48 72.49C37.04 72.49 4 110.85 4 163.93C4 208.11 26.72 243.04 67.67 243.04C84.53 243.04 100.37 237.22 108.97 225.57V225.57ZM76.62 204C55.63 204 45.64 186.88 45.64 162.9C45.64 131.39 62.5 111.53 85.91 111.53C107.59 111.53 117.57 128.65 117.57 151.6C117.57 182.42 100.02 204 76.61 204H76.62Z" />
                      <path d="M242.52 51.26C228.41 51.26 220.49 41.67 220.49 29.34C220.49 14.61 231.85 4 246.65 4C261.45 4 268.68 13.59 268.68 25.92C268.68 40.65 257.32 51.26 242.52 51.26ZM177.47 304C211.89 304 233.57 282.08 238.04 244.75L258.69 76.6H218.08L197.77 240.98C195.7 257.42 188.13 264.61 173.33 264.61C167.82 264.61 162.66 263.58 157.5 261.87L147.86 297.49C157.5 301.94 167.13 304 177.46 304H177.47Z" />
                      <path d="M351.39 204C330.39 204 320.41 186.88 320.41 162.9C320.41 131.39 337.27 111.53 360.68 111.53C382.36 111.53 392.34 128.65 392.34 151.6C392.34 182.42 374.79 204 351.38 204H351.39ZM433.3 242.01L441.56 209.13C431.24 208.1 427.79 202.62 427.79 193.38C427.79 189.27 428.48 183.45 429.17 178.31L441.56 76.6H401.29L399.91 89.27C390.62 77.97 374.44 72.49 357.23 72.49C311.8 72.49 278.76 110.85 278.76 164.27C278.76 208.11 301.13 243.04 342.78 243.04C362.74 243.04 381.67 235.16 390.62 220.09C398.19 236.87 414.37 243.72 433.3 242.01V242.01ZM405.77 51.26C420.57 51.26 431.58 40.64 431.58 25.92C431.58 13.59 424.01 4 409.9 4C395.1 4 384.09 14.62 384.09 29.34C384.09 41.67 391.66 51.26 405.77 51.26ZM338.66 51.26C353.46 51.26 364.47 40.64 364.47 25.92C364.47 13.59 356.9 4 342.79 4C328.68 4 316.98 14.62 316.98 29.34C316.98 41.67 324.55 51.26 338.66 51.26Z" />
                      <path d="M515.69 193.04L540.13 170.09L575.92 238.93H622.04L569.73 142.01L638.56 76.6H582.8L523.26 133.79L538.06 13.58H497.45L469.57 238.92H510.18L515.69 193.03V193.04Z" />
                      <path d="M705.49 76.6H665.57L645.61 238.93H686.22L696.89 151.6C699.99 125.57 716.16 111.53 734.75 111.53C749.89 111.53 757.12 120.43 757.12 138.93C757.12 143.72 756.43 148.18 756.09 151.94L745.42 238.93H786.03L797.39 147.15C798.08 141.33 798.42 135.51 798.42 130.37C798.42 96.12 781.21 72.49 745.76 72.49C728.55 72.49 712.72 77.97 703.77 89.96L705.49 76.6V76.6Z" />
                      <path d="M872.09 140.64C876.57 120.43 890.33 108.45 907.2 108.45C925.44 108.45 934.05 122.49 934.05 140.64H872.1H872.09ZM868.31 173.52H971.22C973.63 164.96 975.01 155.71 975.01 145.78C975.01 108.79 956.08 72.49 909.96 72.49C863.84 72.49 827.01 112.9 827.01 164.61C827.01 205.02 847.32 243.04 899.97 243.04C919.93 243.04 939.55 237.56 957.45 226.94L939.55 192.69C926.47 201.25 913.05 205.02 900.66 205.02C876.91 205.02 868.65 190.98 868.31 173.51V173.52Z" />
                    </g>
                  </svg>
                  <div className="spacing-info-border top"></div>
                  <div className="spacing-info-border bottom"></div>
                  <div className="spacing-info-border center"></div>
                  <div className="spacing-info-border left"></div>
                  <div className="spacing-info-border right"></div>
                  <div className="spacing-text">
                    <p>Y x Y</p>
                    <p>Y x Y</p>
                    <p>Y x Y</p>
                    <p>Y x Y</p>
                  </div>
                </div>
              </div>
              <p className="small mx-2 my-3">If the logo height divided by four = Y.  Then the logo clearance, both vertical and horizontal, must be Y or more. </p>
            </div>
          </div>
        </div>
        <div className="page-footer">
          <Link to="#logo-2">
            <h1>&larr;</h1>
          </Link >
          <Link to="#typography">
            <h1>&rarr;</h1>
          </Link>
        </div>
      </section>
      <section id="typography" className="page white split">
        <div className="page-header">
          <Link to="#index">
            <svg
              viewBox="0 0 980 308"
              xmlns="http://www.w3.org/2000/svg"
              fillRule="evenodd"
            >
              <g >
                <path d="M108.97 225.57L107.25 238.93H146.83L174.71 13.59H134.1L124.81 88.93C115.52 77.97 99.69 72.49 82.48 72.49C37.04 72.49 4 110.85 4 163.93C4 208.11 26.72 243.04 67.67 243.04C84.53 243.04 100.37 237.22 108.97 225.57V225.57ZM76.62 204C55.63 204 45.64 186.88 45.64 162.9C45.64 131.39 62.5 111.53 85.91 111.53C107.59 111.53 117.57 128.65 117.57 151.6C117.57 182.42 100.02 204 76.61 204H76.62Z" />
                <path d="M242.52 51.26C228.41 51.26 220.49 41.67 220.49 29.34C220.49 14.61 231.85 4 246.65 4C261.45 4 268.68 13.59 268.68 25.92C268.68 40.65 257.32 51.26 242.52 51.26ZM177.47 304C211.89 304 233.57 282.08 238.04 244.75L258.69 76.6H218.08L197.77 240.98C195.7 257.42 188.13 264.61 173.33 264.61C167.82 264.61 162.66 263.58 157.5 261.87L147.86 297.49C157.5 301.94 167.13 304 177.46 304H177.47Z" />
                <path d="M351.39 204C330.39 204 320.41 186.88 320.41 162.9C320.41 131.39 337.27 111.53 360.68 111.53C382.36 111.53 392.34 128.65 392.34 151.6C392.34 182.42 374.79 204 351.38 204H351.39ZM433.3 242.01L441.56 209.13C431.24 208.1 427.79 202.62 427.79 193.38C427.79 189.27 428.48 183.45 429.17 178.31L441.56 76.6H401.29L399.91 89.27C390.62 77.97 374.44 72.49 357.23 72.49C311.8 72.49 278.76 110.85 278.76 164.27C278.76 208.11 301.13 243.04 342.78 243.04C362.74 243.04 381.67 235.16 390.62 220.09C398.19 236.87 414.37 243.72 433.3 242.01V242.01ZM405.77 51.26C420.57 51.26 431.58 40.64 431.58 25.92C431.58 13.59 424.01 4 409.9 4C395.1 4 384.09 14.62 384.09 29.34C384.09 41.67 391.66 51.26 405.77 51.26ZM338.66 51.26C353.46 51.26 364.47 40.64 364.47 25.92C364.47 13.59 356.9 4 342.79 4C328.68 4 316.98 14.62 316.98 29.34C316.98 41.67 324.55 51.26 338.66 51.26Z" />
                <path d="M515.69 193.04L540.13 170.09L575.92 238.93H622.04L569.73 142.01L638.56 76.6H582.8L523.26 133.79L538.06 13.58H497.45L469.57 238.92H510.18L515.69 193.03V193.04Z" />
                <path d="M705.49 76.6H665.57L645.61 238.93H686.22L696.89 151.6C699.99 125.57 716.16 111.53 734.75 111.53C749.89 111.53 757.12 120.43 757.12 138.93C757.12 143.72 756.43 148.18 756.09 151.94L745.42 238.93H786.03L797.39 147.15C798.08 141.33 798.42 135.51 798.42 130.37C798.42 96.12 781.21 72.49 745.76 72.49C728.55 72.49 712.72 77.97 703.77 89.96L705.49 76.6V76.6Z" />
                <path d="M872.09 140.64C876.57 120.43 890.33 108.45 907.2 108.45C925.44 108.45 934.05 122.49 934.05 140.64H872.1H872.09ZM868.31 173.52H971.22C973.63 164.96 975.01 155.71 975.01 145.78C975.01 108.79 956.08 72.49 909.96 72.49C863.84 72.49 827.01 112.9 827.01 164.61C827.01 205.02 847.32 243.04 899.97 243.04C919.93 243.04 939.55 237.56 957.45 226.94L939.55 192.69C926.47 201.25 913.05 205.02 900.66 205.02C876.91 205.02 868.65 190.98 868.31 173.51V173.52Z" />
              </g>
            </svg>
          </Link>
          <p className="dimmed">Brand Guidelines / Guidelines</p>
        </div>
        <div className="page-content">
          <div className="left">
            <div className="text-container mb-5">
              <h2>
                Typography
              </h2>
              <p className="text-md-center"><small>(1/2)</small></p>
            </div>
          </div>
          <div className="right">
            <div className="text-container">
              <h5 className="">Headline font</h5>
              <h2>Menca</h2>
              <p>Menca takes cues from the lettering of engineering, found on road signage and industrial metal plaques.</p>
              <div className=" mt-5 font-sample">
                <h1 className="display-1 ">A b c d e f g h i j k l m </h1>
                <h1 className="display-1 ">n o p q r s t u v w x y z</h1>
                <h1 className="display-1 "> 0 1 2 3 4 5 6 7 8 9</h1>
              </div>
            </div>
          </div>
        </div>
        <div className="page-footer">
          <Link to="#logo-3">
            <h1>&larr;</h1>
          </Link >
          <Link to="#typography-2">
            <h1>&rarr;</h1>
          </Link >
        </div>
      </section>
      <section id="typography-2" className="page white split">
        <div className="page-header">
          <Link to="#index">
            <svg
              viewBox="0 0 980 308"
              xmlns="http://www.w3.org/2000/svg"
              fillRule="evenodd"
            >
              <g >
                <path d="M108.97 225.57L107.25 238.93H146.83L174.71 13.59H134.1L124.81 88.93C115.52 77.97 99.69 72.49 82.48 72.49C37.04 72.49 4 110.85 4 163.93C4 208.11 26.72 243.04 67.67 243.04C84.53 243.04 100.37 237.22 108.97 225.57V225.57ZM76.62 204C55.63 204 45.64 186.88 45.64 162.9C45.64 131.39 62.5 111.53 85.91 111.53C107.59 111.53 117.57 128.65 117.57 151.6C117.57 182.42 100.02 204 76.61 204H76.62Z" />
                <path d="M242.52 51.26C228.41 51.26 220.49 41.67 220.49 29.34C220.49 14.61 231.85 4 246.65 4C261.45 4 268.68 13.59 268.68 25.92C268.68 40.65 257.32 51.26 242.52 51.26ZM177.47 304C211.89 304 233.57 282.08 238.04 244.75L258.69 76.6H218.08L197.77 240.98C195.7 257.42 188.13 264.61 173.33 264.61C167.82 264.61 162.66 263.58 157.5 261.87L147.86 297.49C157.5 301.94 167.13 304 177.46 304H177.47Z" />
                <path d="M351.39 204C330.39 204 320.41 186.88 320.41 162.9C320.41 131.39 337.27 111.53 360.68 111.53C382.36 111.53 392.34 128.65 392.34 151.6C392.34 182.42 374.79 204 351.38 204H351.39ZM433.3 242.01L441.56 209.13C431.24 208.1 427.79 202.62 427.79 193.38C427.79 189.27 428.48 183.45 429.17 178.31L441.56 76.6H401.29L399.91 89.27C390.62 77.97 374.44 72.49 357.23 72.49C311.8 72.49 278.76 110.85 278.76 164.27C278.76 208.11 301.13 243.04 342.78 243.04C362.74 243.04 381.67 235.16 390.62 220.09C398.19 236.87 414.37 243.72 433.3 242.01V242.01ZM405.77 51.26C420.57 51.26 431.58 40.64 431.58 25.92C431.58 13.59 424.01 4 409.9 4C395.1 4 384.09 14.62 384.09 29.34C384.09 41.67 391.66 51.26 405.77 51.26ZM338.66 51.26C353.46 51.26 364.47 40.64 364.47 25.92C364.47 13.59 356.9 4 342.79 4C328.68 4 316.98 14.62 316.98 29.34C316.98 41.67 324.55 51.26 338.66 51.26Z" />
                <path d="M515.69 193.04L540.13 170.09L575.92 238.93H622.04L569.73 142.01L638.56 76.6H582.8L523.26 133.79L538.06 13.58H497.45L469.57 238.92H510.18L515.69 193.03V193.04Z" />
                <path d="M705.49 76.6H665.57L645.61 238.93H686.22L696.89 151.6C699.99 125.57 716.16 111.53 734.75 111.53C749.89 111.53 757.12 120.43 757.12 138.93C757.12 143.72 756.43 148.18 756.09 151.94L745.42 238.93H786.03L797.39 147.15C798.08 141.33 798.42 135.51 798.42 130.37C798.42 96.12 781.21 72.49 745.76 72.49C728.55 72.49 712.72 77.97 703.77 89.96L705.49 76.6V76.6Z" />
                <path d="M872.09 140.64C876.57 120.43 890.33 108.45 907.2 108.45C925.44 108.45 934.05 122.49 934.05 140.64H872.1H872.09ZM868.31 173.52H971.22C973.63 164.96 975.01 155.71 975.01 145.78C975.01 108.79 956.08 72.49 909.96 72.49C863.84 72.49 827.01 112.9 827.01 164.61C827.01 205.02 847.32 243.04 899.97 243.04C919.93 243.04 939.55 237.56 957.45 226.94L939.55 192.69C926.47 201.25 913.05 205.02 900.66 205.02C876.91 205.02 868.65 190.98 868.31 173.51V173.52Z" />
              </g>
            </svg>
          </Link>
          <p className="dimmed">Brand Guidelines / Guidelines</p>
        </div>
        <div className="page-content">
          <div className="left">
            <div className="text-container mb-5">
              <h2>
                Typography
              </h2>
              <p className="text-md-center"><small>(2/2)</small></p>
            </div>
          </div>
          <div className="right">
            <div className="text-container">
              <h5 className="">Paragraph font</h5>
              <h2>Atkinson Hyperlegible</h2>
              <p>Atkinson Hyperlegible, named after the founder of the Braille Institute, has been developed specifically to increase legibility for readers with low vision, and to improve comprehension.</p>
              <p>Having a traditional grotesque sans-serif at its core, it departs from tradition to incorporate unambiguous, distinctive elements—and at times, unexpected forms—always with the goal of increasing character recognition and ultimately improve reading.</p>
              <div className=" mt-5 font-sample">
                <h1 className="display-1 atkinson">A b c d e f g h i j k l m </h1>
                <h1 className="display-1 atkinson">n o p q r s t u v w x y z</h1>
                <h1 className="display-1 atkinson"> 0 1 2 3 4 5 6 7 8 9</h1>
              </div>
            </div>
          </div>
        </div>
        <div className="page-footer">
          <Link to="#typography">
            <h1>&larr;</h1>
          </Link >
          <Link to="#colors">
            <h1>&rarr;</h1>
          </Link>
        </div>
      </section>
      <section id="colors" className="page white split">
        <div className="page-header">
          <Link to="#index">
            <svg
              viewBox="0 0 980 308"
              xmlns="http://www.w3.org/2000/svg"
              fillRule="evenodd"
            >
              <g >
                <path d="M108.97 225.57L107.25 238.93H146.83L174.71 13.59H134.1L124.81 88.93C115.52 77.97 99.69 72.49 82.48 72.49C37.04 72.49 4 110.85 4 163.93C4 208.11 26.72 243.04 67.67 243.04C84.53 243.04 100.37 237.22 108.97 225.57V225.57ZM76.62 204C55.63 204 45.64 186.88 45.64 162.9C45.64 131.39 62.5 111.53 85.91 111.53C107.59 111.53 117.57 128.65 117.57 151.6C117.57 182.42 100.02 204 76.61 204H76.62Z" />
                <path d="M242.52 51.26C228.41 51.26 220.49 41.67 220.49 29.34C220.49 14.61 231.85 4 246.65 4C261.45 4 268.68 13.59 268.68 25.92C268.68 40.65 257.32 51.26 242.52 51.26ZM177.47 304C211.89 304 233.57 282.08 238.04 244.75L258.69 76.6H218.08L197.77 240.98C195.7 257.42 188.13 264.61 173.33 264.61C167.82 264.61 162.66 263.58 157.5 261.87L147.86 297.49C157.5 301.94 167.13 304 177.46 304H177.47Z" />
                <path d="M351.39 204C330.39 204 320.41 186.88 320.41 162.9C320.41 131.39 337.27 111.53 360.68 111.53C382.36 111.53 392.34 128.65 392.34 151.6C392.34 182.42 374.79 204 351.38 204H351.39ZM433.3 242.01L441.56 209.13C431.24 208.1 427.79 202.62 427.79 193.38C427.79 189.27 428.48 183.45 429.17 178.31L441.56 76.6H401.29L399.91 89.27C390.62 77.97 374.44 72.49 357.23 72.49C311.8 72.49 278.76 110.85 278.76 164.27C278.76 208.11 301.13 243.04 342.78 243.04C362.74 243.04 381.67 235.16 390.62 220.09C398.19 236.87 414.37 243.72 433.3 242.01V242.01ZM405.77 51.26C420.57 51.26 431.58 40.64 431.58 25.92C431.58 13.59 424.01 4 409.9 4C395.1 4 384.09 14.62 384.09 29.34C384.09 41.67 391.66 51.26 405.77 51.26ZM338.66 51.26C353.46 51.26 364.47 40.64 364.47 25.92C364.47 13.59 356.9 4 342.79 4C328.68 4 316.98 14.62 316.98 29.34C316.98 41.67 324.55 51.26 338.66 51.26Z" />
                <path d="M515.69 193.04L540.13 170.09L575.92 238.93H622.04L569.73 142.01L638.56 76.6H582.8L523.26 133.79L538.06 13.58H497.45L469.57 238.92H510.18L515.69 193.03V193.04Z" />
                <path d="M705.49 76.6H665.57L645.61 238.93H686.22L696.89 151.6C699.99 125.57 716.16 111.53 734.75 111.53C749.89 111.53 757.12 120.43 757.12 138.93C757.12 143.72 756.43 148.18 756.09 151.94L745.42 238.93H786.03L797.39 147.15C798.08 141.33 798.42 135.51 798.42 130.37C798.42 96.12 781.21 72.49 745.76 72.49C728.55 72.49 712.72 77.97 703.77 89.96L705.49 76.6V76.6Z" />
                <path d="M872.09 140.64C876.57 120.43 890.33 108.45 907.2 108.45C925.44 108.45 934.05 122.49 934.05 140.64H872.1H872.09ZM868.31 173.52H971.22C973.63 164.96 975.01 155.71 975.01 145.78C975.01 108.79 956.08 72.49 909.96 72.49C863.84 72.49 827.01 112.9 827.01 164.61C827.01 205.02 847.32 243.04 899.97 243.04C919.93 243.04 939.55 237.56 957.45 226.94L939.55 192.69C926.47 201.25 913.05 205.02 900.66 205.02C876.91 205.02 868.65 190.98 868.31 173.51V173.52Z" />
              </g>
            </svg>
          </Link>
          <p className="dimmed">Brand Guidelines / Guidelines</p>
        </div>
        <div className="page-content">
          <div className="left">
            <div className="text-container mb-5">
              <h2>
                Colors
              </h2>
            </div>
          </div>
          <div className="right">
            <div className="text-container">
              <h2>In the shadows</h2>
              <p>Djäkne Startup Studio acts in the shadows of the entrepreneurs and tech companies. Thus we do not take up andy visual attention with color, we stay black and white or even black on black if needed.</p>
            </div>
            <div className="color-box-container">
              <div className="color-box">
                <div className="color black global-image-style mb-4"></div>
                <div class="color-details">
                  <div class="name col-sm-12 mb-3"><h4> Our Black</h4></div>
                  <div class="row color-type">
                    <div class="col-xl-5 col-lg-5 col-md-5 col-sm-5 color-type-label"><small>HEX</small></div>
                    <div class="col-xl-7 col-lg-7 col-md-7 col-sm-7 color-type-value"><small>#171b21</small></div>
                  </div>
                  <div class="row color-type">
                    <div class="col-xl-5 col-lg-5 col-md-5 col-sm-5 color-type-label"><small>RGB</small></div>
                    <div class="col-xl-7 col-lg-7 col-md-7 col-sm-7 color-type-value"><small>23, 27, 33</small></div>
                  </div>
                  <div class="row color-type">
                    <div class="col-xl-5 col-lg-5 col-md-5 col-sm-5 color-type-label"><small>CMYK</small></div>
                    <div class="col-xl-7 col-lg-7 col-md-7 col-sm-7 color-type-value"><small>78,70,60,75</small></div>
                  </div>
                </div>
              </div>
              <div className="color-box">
                <div className="color white global-image-style mb-4"></div>
                <div class="color-details">
                  <div class="name col-sm-12 mb-3"><h4>Our White</h4></div>
                  <div class="row color-type">
                    <div class="col-xl-5 col-lg-5 col-md-5 col-sm-5 color-type-label"><small>HEX</small></div>
                    <div class="col-xl-7 col-lg-7 col-md-7 col-sm-7 color-type-value"><small>#e7e7e7</small></div>
                  </div>
                  <div class="row color-type">
                    <div class="col-xl-5 col-lg-5 col-md-5 col-sm-5 color-type-label"><small>RGB</small></div>
                    <div class="col-xl-7 col-lg-7 col-md-7 col-sm-7 color-type-value"><small>231, 231, 231</small></div>
                  </div>
                  <div class="row color-type">
                    <div class="col-xl-5 col-lg-5 col-md-5 col-sm-5 color-type-label"><small>CMYK</small></div>
                    <div class="col-xl-7 col-lg-7 col-md-7 col-sm-7 color-type-value"><small>8,6,6,0</small></div>
                  </div>
                </div>
              </div>
              <div className="color-box">
                <div className="color accent global-image-style mb-4"></div>
                <div class="color-details">
                  <div class="name col-sm-12 mb-3"><h4> Our Accent</h4></div>
                  <div class="row color-type">
                    <div class="col-xl-5 col-lg-5 col-md-5 col-sm-5 color-type-label"><small>HEX</small></div>
                    <div class="col-xl-7 col-lg-7 col-md-7 col-sm-7 color-type-value"><small>#61F5C0</small></div>
                  </div>
                  <div class="row color-type">
                    <div class="col-xl-5 col-lg-5 col-md-5 col-sm-5 color-type-label"><small>RGB</small></div>
                    <div class="col-xl-7 col-lg-7 col-md-7 col-sm-7 color-type-value"><small>97, 245, 192</small></div>
                  </div>
                  <div class="row color-type">
                    <div class="col-xl-5 col-lg-5 col-md-5 col-sm-5 color-type-label"><small>CMYK</small></div>
                    <div class="col-xl-7 col-lg-7 col-md-7 col-sm-7 color-type-value"><small>49,0,39,0</small></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="page-footer">
          <Link to="#typography-2">
            <h1>&larr;</h1>
          </Link>
          <Link to="#name">
            <h1>&rarr;</h1>
          </Link >
        </div>
      </section>
      <section id="name" className="page white split">
        <div className="page-header">
          <Link to="#index">
            <svg
              viewBox="0 0 980 308"
              xmlns="http://www.w3.org/2000/svg"
              fillRule="evenodd"
            >
              <g >
                <path d="M108.97 225.57L107.25 238.93H146.83L174.71 13.59H134.1L124.81 88.93C115.52 77.97 99.69 72.49 82.48 72.49C37.04 72.49 4 110.85 4 163.93C4 208.11 26.72 243.04 67.67 243.04C84.53 243.04 100.37 237.22 108.97 225.57V225.57ZM76.62 204C55.63 204 45.64 186.88 45.64 162.9C45.64 131.39 62.5 111.53 85.91 111.53C107.59 111.53 117.57 128.65 117.57 151.6C117.57 182.42 100.02 204 76.61 204H76.62Z" />
                <path d="M242.52 51.26C228.41 51.26 220.49 41.67 220.49 29.34C220.49 14.61 231.85 4 246.65 4C261.45 4 268.68 13.59 268.68 25.92C268.68 40.65 257.32 51.26 242.52 51.26ZM177.47 304C211.89 304 233.57 282.08 238.04 244.75L258.69 76.6H218.08L197.77 240.98C195.7 257.42 188.13 264.61 173.33 264.61C167.82 264.61 162.66 263.58 157.5 261.87L147.86 297.49C157.5 301.94 167.13 304 177.46 304H177.47Z" />
                <path d="M351.39 204C330.39 204 320.41 186.88 320.41 162.9C320.41 131.39 337.27 111.53 360.68 111.53C382.36 111.53 392.34 128.65 392.34 151.6C392.34 182.42 374.79 204 351.38 204H351.39ZM433.3 242.01L441.56 209.13C431.24 208.1 427.79 202.62 427.79 193.38C427.79 189.27 428.48 183.45 429.17 178.31L441.56 76.6H401.29L399.91 89.27C390.62 77.97 374.44 72.49 357.23 72.49C311.8 72.49 278.76 110.85 278.76 164.27C278.76 208.11 301.13 243.04 342.78 243.04C362.74 243.04 381.67 235.16 390.62 220.09C398.19 236.87 414.37 243.72 433.3 242.01V242.01ZM405.77 51.26C420.57 51.26 431.58 40.64 431.58 25.92C431.58 13.59 424.01 4 409.9 4C395.1 4 384.09 14.62 384.09 29.34C384.09 41.67 391.66 51.26 405.77 51.26ZM338.66 51.26C353.46 51.26 364.47 40.64 364.47 25.92C364.47 13.59 356.9 4 342.79 4C328.68 4 316.98 14.62 316.98 29.34C316.98 41.67 324.55 51.26 338.66 51.26Z" />
                <path d="M515.69 193.04L540.13 170.09L575.92 238.93H622.04L569.73 142.01L638.56 76.6H582.8L523.26 133.79L538.06 13.58H497.45L469.57 238.92H510.18L515.69 193.03V193.04Z" />
                <path d="M705.49 76.6H665.57L645.61 238.93H686.22L696.89 151.6C699.99 125.57 716.16 111.53 734.75 111.53C749.89 111.53 757.12 120.43 757.12 138.93C757.12 143.72 756.43 148.18 756.09 151.94L745.42 238.93H786.03L797.39 147.15C798.08 141.33 798.42 135.51 798.42 130.37C798.42 96.12 781.21 72.49 745.76 72.49C728.55 72.49 712.72 77.97 703.77 89.96L705.49 76.6V76.6Z" />
                <path d="M872.09 140.64C876.57 120.43 890.33 108.45 907.2 108.45C925.44 108.45 934.05 122.49 934.05 140.64H872.1H872.09ZM868.31 173.52H971.22C973.63 164.96 975.01 155.71 975.01 145.78C975.01 108.79 956.08 72.49 909.96 72.49C863.84 72.49 827.01 112.9 827.01 164.61C827.01 205.02 847.32 243.04 899.97 243.04C919.93 243.04 939.55 237.56 957.45 226.94L939.55 192.69C926.47 201.25 913.05 205.02 900.66 205.02C876.91 205.02 868.65 190.98 868.31 173.51V173.52Z" />
              </g>
            </svg>
          </Link>
          <p className="dimmed">Brand Guidelines / Guidelines</p>
        </div>
        <div className="page-content">
          <div className="left">
            <div className="text-container mb-5">
              <h2>
                Use of the company name
              </h2>
            </div>
          </div>
          <div className="right">
            <div className="text-container">
              <h2>Djäkne Startup Studio</h2>
              <p>The name “Djäkne Startup Studio” must be used in its exact form. Its appearance must not vary by abbreviating it, making it into an acronym, or combining it with any other words or any figurative elements, changing its spelling or using improper capitalization.</p>
              <p>Do not:</p>
              <ul>
                <li><p>Shorten Djäkne Startup Studio to an initial or abbreviation (“Djäkne”)</p></li>
                <li><p>Turn Djäkne Startup Studio into an acronym (such as “DSS”)</p></li>
              </ul>
            </div>
          </div>
        </div>
        <div className="page-footer">
          <Link to="#colors">
            <h1>&larr;</h1>
          </Link >
          <Link to="#photography">
            <h1>&rarr;</h1>
          </Link >
        </div>
      </section>

      <section id="photography" className="page white split">
        <div className="page-header">
          <Link to="#index">
            <svg
              viewBox="0 0 980 308"
              xmlns="http://www.w3.org/2000/svg"
              fillRule="evenodd"
            >
              <g >
                <path d="M108.97 225.57L107.25 238.93H146.83L174.71 13.59H134.1L124.81 88.93C115.52 77.97 99.69 72.49 82.48 72.49C37.04 72.49 4 110.85 4 163.93C4 208.11 26.72 243.04 67.67 243.04C84.53 243.04 100.37 237.22 108.97 225.57V225.57ZM76.62 204C55.63 204 45.64 186.88 45.64 162.9C45.64 131.39 62.5 111.53 85.91 111.53C107.59 111.53 117.57 128.65 117.57 151.6C117.57 182.42 100.02 204 76.61 204H76.62Z" />
                <path d="M242.52 51.26C228.41 51.26 220.49 41.67 220.49 29.34C220.49 14.61 231.85 4 246.65 4C261.45 4 268.68 13.59 268.68 25.92C268.68 40.65 257.32 51.26 242.52 51.26ZM177.47 304C211.89 304 233.57 282.08 238.04 244.75L258.69 76.6H218.08L197.77 240.98C195.7 257.42 188.13 264.61 173.33 264.61C167.82 264.61 162.66 263.58 157.5 261.87L147.86 297.49C157.5 301.94 167.13 304 177.46 304H177.47Z" />
                <path d="M351.39 204C330.39 204 320.41 186.88 320.41 162.9C320.41 131.39 337.27 111.53 360.68 111.53C382.36 111.53 392.34 128.65 392.34 151.6C392.34 182.42 374.79 204 351.38 204H351.39ZM433.3 242.01L441.56 209.13C431.24 208.1 427.79 202.62 427.79 193.38C427.79 189.27 428.48 183.45 429.17 178.31L441.56 76.6H401.29L399.91 89.27C390.62 77.97 374.44 72.49 357.23 72.49C311.8 72.49 278.76 110.85 278.76 164.27C278.76 208.11 301.13 243.04 342.78 243.04C362.74 243.04 381.67 235.16 390.62 220.09C398.19 236.87 414.37 243.72 433.3 242.01V242.01ZM405.77 51.26C420.57 51.26 431.58 40.64 431.58 25.92C431.58 13.59 424.01 4 409.9 4C395.1 4 384.09 14.62 384.09 29.34C384.09 41.67 391.66 51.26 405.77 51.26ZM338.66 51.26C353.46 51.26 364.47 40.64 364.47 25.92C364.47 13.59 356.9 4 342.79 4C328.68 4 316.98 14.62 316.98 29.34C316.98 41.67 324.55 51.26 338.66 51.26Z" />
                <path d="M515.69 193.04L540.13 170.09L575.92 238.93H622.04L569.73 142.01L638.56 76.6H582.8L523.26 133.79L538.06 13.58H497.45L469.57 238.92H510.18L515.69 193.03V193.04Z" />
                <path d="M705.49 76.6H665.57L645.61 238.93H686.22L696.89 151.6C699.99 125.57 716.16 111.53 734.75 111.53C749.89 111.53 757.12 120.43 757.12 138.93C757.12 143.72 756.43 148.18 756.09 151.94L745.42 238.93H786.03L797.39 147.15C798.08 141.33 798.42 135.51 798.42 130.37C798.42 96.12 781.21 72.49 745.76 72.49C728.55 72.49 712.72 77.97 703.77 89.96L705.49 76.6V76.6Z" />
                <path d="M872.09 140.64C876.57 120.43 890.33 108.45 907.2 108.45C925.44 108.45 934.05 122.49 934.05 140.64H872.1H872.09ZM868.31 173.52H971.22C973.63 164.96 975.01 155.71 975.01 145.78C975.01 108.79 956.08 72.49 909.96 72.49C863.84 72.49 827.01 112.9 827.01 164.61C827.01 205.02 847.32 243.04 899.97 243.04C919.93 243.04 939.55 237.56 957.45 226.94L939.55 192.69C926.47 201.25 913.05 205.02 900.66 205.02C876.91 205.02 868.65 190.98 868.31 173.51V173.52Z" />
              </g>
            </svg>
          </Link>
          <p className="dimmed">Brand Guidelines / Guidelines</p>
        </div>
        <div className="page-content">
          <div className="left">
            <div className="text-container mb-5">
              <h2>
                Photography
              </h2>
            </div>
          </div>
          <div className="right">
            <div class="image-gallery">
              <div class="column">
                <div class="image-item">
                  <img
                    className="global-image-style"
                    src={getFullsizeImage("https://res.cloudinary.com/djakne/image/upload/v1618217329/DSC_0453_vi0yoz.jpg")}
                    alt="image example"
                  />
                  {/* <div class="overlay"><span></span></div> */}
                </div>
                <div class="image-item">
                  <img
                    className="global-image-style"
                    src={getFullsizeImage("https://res.cloudinary.com/djakne/image/upload/v1626088619/P1029281-01_qobxla.jpg")}
                    alt="image example"
                  />
                  {/* <div class="overlay"><span></span></div> */}
                </div>
                <div class="image-item">
                  <img
                    className="global-image-style"
                    src={getFullsizeImage("https://res.cloudinary.com/djakne/image/upload/v1665064664/lars_ft0jdz.png")}
                    alt="image example"
                  />
                  {/* <div class="overlay"><span></span></div> */}
                </div>

                <div class="image-item">
                  <img
                    className="global-image-style"
                    src={getFullsizeImage("https://res.cloudinary.com/djakne/image/upload/v1621864610/t1_j4ycti.jpg")}
                    alt="image example"
                  />
                  {/* <div class="overlay"><span></span></div> */}
                </div>
              </div>
              <div class="column">
                <div class="image-item">
                  <img
                    className="global-image-style"
                    src={getFullsizeImage("https://res.cloudinary.com/djakne/image/upload/v1617776386/P1029391-01_tesikm.jpg")}
                    alt="image example"
                  />
                  <div class="overlay"><span></span></div>
                </div>
                <div class="image-item">
                  <img
                    className="global-image-style"
                    src={getFullsizeImage("https://res.cloudinary.com/djakne/image/upload/v1668082769/c442d8_132972bdc563445fb6ea7433706f9f15_mv2_uhprmo.webp")}
                    alt="image example"
                  />
                  <div class="overlay"><span></span></div>
                </div>
                <div class="image-item">
                  <img
                    className="global-image-style"
                    src={getFullsizeImage("https://res.cloudinary.com/djakne/image/upload/v1665064665/korhan_krmevr.png")}
                    alt="image example"
                  />
                  {/* <div class="overlay"><span></span></div> */}
                </div>

              </div>
              <div class="column">
                <div class="image-item">
                  <img
                    className="global-image-style"
                    src={getFullsizeImage("https://res.cloudinary.com/djakne/image/upload/v1665475357/jerry_wjxczh.png")}
                    alt="image example"
                  />
                  {/* <div class="overlay"><span></span></div> */}
                </div>
                <div class="image-item">
                  <img
                    className="global-image-style"
                    src={getFullsizeImage("https://res.cloudinary.com/djakne/image/upload/v1632733004/1628499722458_myjaee.jpg")}
                    alt="image example"
                  />
                  {/* <div class="overlay"><span></span></div> */}
                </div>
                <div class="image-item">
                  <img
                    className="global-image-style"
                    src={getFullsizeImage("https://res.cloudinary.com/djakne/image/upload/v1618218106/DSC_0008_qbmumj.jpg")}
                    alt="image example"
                  />
                  {/* <div class="overlay"><span></span></div> */}
                </div>
              </div>
            </div>
            <div className="text-container mt-5">
              <p>Our images reflect our reality and captures momenta from our daily life. Form follows reality and function, thus we avoid over editing and posing as far as we can. To facilitate natural poses and capturing our moments as naturally as possible we take photos from a distance with a short depth of field and keep focus on the main obective.</p>
            </div>
          </div>
        </div>
        <div className="page-footer">
          <Link to="#name">
            <h1>&larr;</h1>
          </Link >

          <h1></h1>

        </div>
      </section>
    </main>
  </div>
)

export default Brand
